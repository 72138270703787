<template>
<div class="
text-blue-500
py-3
pl-2
md:pl-1
font-semibold
w-full
lg:w-40
uppercase
text-left
">{{title}}</div>
</template>

<script>
  export default {
    props :['title']
  }
</script>
