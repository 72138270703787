<template>
  <div class="w-full flex flex-col items-start">
    <div class="border-b-2 border-blue-100 bg-gray-50 w-full">
      <Title title="Collections" />
    </div>
    <div class="lg:flex lg:flex-col lg:justify-start lg:items-center space-y-6 lg:space-y-0 h-full w-full">
      <!--COLLECTIONS-->
      <div class="grid grid-cols-12 gap-4 w-full">
        <div class="col-span-12 lg:col-span-6 my-1 lg:my-0">
          <div class="w-full relative" v-click-away="closeSelectCollections">
            <label for="select_colection" class="inline-flex items-center cursor-pointer">
              <span class="label">Select Collection</span>
            </label>
            <button id="select_collection" class="btn-xs btn-select" @click="$store.state.Menu.isCollectionsOptionsOpen = !$store.state.Menu.isCollectionsOptionsOpen">
              <span>{{ $store.state.BasicSegment.isCollectionSelected ? $store.state.BasicSegment.isCollectionSelected.name : "Select" }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down w-5 h-5 ml-4 text-blue-500" width="28" height="28" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="6 9 12 15 18 9" />
              </svg>
            </button>
            <!-- Dropdown menu -->
            <TransitionFade>
              <ul class="w-full absolute overflow-y-auto left-0 mt-2 bg-white dark:bg-gray-800 rounded-lg shadow-xl z-20 border divide-y divide-gray-100" v-if="$store.state.Menu.isCollectionsOptionsOpen" :class="[$store.state.Audience.collection && $store.state.Audience.collection.length > 7 ? 'h-96' : 'h-auto']">
                <li class="h-14 w-full text-sm flex flex-col items-start justify-center cursor-pointer hover:bg-gray-50 transition ease-out duration-300" v-for="c in $store.state.Audience.collection" :key="c.id" @click="selectCollection(c)">
                  <span class="font-semibold text-gray-500 inline-block px-2">{{ c.name }}</span>
                </li>
              </ul>
            </TransitionFade>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6 lg:mt-auto">
          <button class="btn btn-xs btn-secondary mb-0" @click="openModalCollection">New collection</button>
        </div>
        <div class="grid-cols-12 w-full" v-if="$store.state.BasicSegment.isCollectionSelected">
          <button @click="resetCollectionSelected" class="bg-red-100 text-red-600 rounded-full px-2 py-1 flex flex-row items-center justifuy-center text-xs">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-4 h-4 mr-1 stroke-current">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
            CANCEL
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TransitionFade from "@/components/TransitionFade"
import Title from "@/components/Audience/Basic/Title"

export default {
  components: {
    TransitionFade,
    Title
  },
  data() {
    return {}
  },
  methods: {
    closeSelectCollections: function () {
      this.$store.dispatch("Menu/closeSelectCollections")
    },
    selectCollection: function (val) {
      this.$store.dispatch("BasicSegment/setCollection", val)
      this.closeSelectCollections()
    },
    resetCollectionSelected: function () {
      this.$store.dispatch("BasicSegment/setCollection")
    },
    openModalCollection: function () {
      this.$store.dispatch("Menu/openCollectionModal")
    }
  }
}
</script>

<style lang="scss" scoped></style>
