<template>
  <div class="w-full">
    <div class="flex flex-col">
      <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div class="mx-auto py-5">
          <div class="grid grid-cols-5 md:grid-cols-6 gap-4 w-full items-center md:px-0">
            <div>
              <span class="p-input-icon-left w-full">
                <i class="pi pi-search" />
                <InputText
                  type="text"
                  v-model="search.text"
                  v-debounce:700ms="getSegmentIntegrationJobs"
                  class="p-inputtext-sm shadow-sm w-full"
                  autocomplete="off"
                  :placeholder="$t('audience.search')"
                />
              </span>
            </div>
            <div>
              <span class="p-input-icon-left w-full">
                <i class="pi pi-search" />
                <Calendar :placeholder="$t('audience.segmentIntegrationJob.date_search')" class="p-inputtext-sm shadow-sm w-full" v-model="search.dates" selectionMode="range" :manualInput="false" @update:modelValue="getSegmentIntegrationJobs" />
              </span>
            </div>
            <div>
              <span class="p-input-icon-left w-full">
                <i class="pi pi-search" />
                <MultiSelect
                  v-model="search.channelSelect"
                  :placeholder="$t('navbar.channels')"
                  :options="channels"
                  :filter="true"
                  class="w-full p-multiselect-icon leading-3"
                  optionValue="id"
                  optionLabel="name"
                  @change="getSegmentIntegrationJobs"
                />
              </span>
            </div>
            <div v-if="!segment.id">
              <span class="p-input-icon-left w-full">
                <i class="pi pi-search" />
                <MultiSelect
                  v-model="search.segmentSelect"
                  :placeholder="$t('cardProject.segments')"
                  :options="segments"
                  :filter="true"
                  class="w-full p-multiselect-icon leading-3"
                  optionValue="id"
                  optionLabel="name"
                  @change="getSegmentIntegrationJobs"
                />
              </span>
            </div>

            <div class="relative col-span-2 flex flex-row gap-5">
              <p
                class="text-sm font-semibold text-slate-500 hover:text-slate-700 cursor-pointer mt-2"
                @click="resetSearch"
                v-if="search.text != '' || search.dates.length > 0 || search.channelSelect.length > 0 || search.segmentSelect.length > 0"
              >
                {{ $t('clearFilters') }}
              </p>

              <div class="relative flex flex-1 self-end justify-end">
                <div class="relative">
                  <Button @click="getSegmentIntegrationJobs(true)" :label="$t('audience.segmentIntegrationJob.reloadJobs')" :loading="loadings.reloadJob" icon="pi pi-replay" iconPos="left" class="p-button-light shadow" />
                </div>
              </div>
            </div>

          </div>
        </div>
        <BlockUI :blocked="loadings.general" :baseZIndex="100" :autoZIndex="false">
          <BlockUILoader v-if="loadings.general" :text="$t('wizard.validating')" />

          <div class="flex flex-col items-center mb-2 w-full">
            <PaginationSelect v-if="pagination.path != ''" :current_page="pagination.current_page" :from="pagination.from" :last_page="pagination.last_page" :path="pagination.path" :per_page="pagination.per_page" :to="pagination.to" :total="pagination.total" :dropdown="true" @change-page="paginationChange" />
          </div>
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900">{{ $t("channel") }}</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900">{{ $t("audience.name") }}</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900">{{ $t("audience.createDate") }}</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900">{{ $t("audience.state") }}</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900">{{ $t("audience.segmentIntegrationJob.date_start") }}</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900">{{ $t("audience.segmentIntegrationJob.total_profiles_sync") }}</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900">ETA</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900">{{ $t("audience.segmentIntegrationJob.date_end") }}</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900">{{ $t("audience.segmentIntegrationJob.memory_peak_usage") }}</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-normal text-gray-900"></th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="(job, index) in segmentIntegrationJobs" :key="index">
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <img :src="job.segment_integration.service_segment.channel.avatar_short" class="w-8 h-8 cursor-pointer" v-tooltip.top="job.segment_integration.service_segment.channel.name" />
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="font-medium text-gray-800 flex flex-row">{{ job.segment_integration.segment.name }}</div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="font-medium text-gray-800 flex flex-row">{{ $moment(job.created_at).format("DD-MM-YYYY, hh:mm") || $t("audience.notData") }}</div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="font-medium text-gray-800 flex flex-row">{{ $t(`audience.segmentIntegrationJob.states.${job.state}`) }}</div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="font-medium text-gray-800 flex flex-row">{{ job.date_init ? $moment(job.date_init).format("DD-MM-YYYY, hh:mm") : $t("audience.notData") }}</div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="font-medium text-gray-800 flex flex-row">{{ job.total_profile_sync }}</div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="font-medium text-gray-800 flex flex-row">{{ job.time_estimated_end != 0 && job.time_estimated_end != null ? formatTime(job.time_estimated_end) + "..." : $t("audience.segmentIntegrationJob.time_estimated_complete") }}</div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="font-medium text-gray-800 flex flex-row">{{ job.date_end ? $moment(job.date_end).format("DD-MM-YYYY, hh:mm") : $t("audience.notData") }}</div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="font-medium text-gray-800 flex flex-row">{{ job.memory_peak_usage ? job.memory_peak_usage + 'MB' : $t("audience.notData") }}</div>
                  </td>
                  <td class="flex-center space-x-2 justify-end relative whitespace-nowrap p-4 text-sm font-medium">
                    <Button v-if="!job.kill && !['FINISH', 'ERROR'].includes(job.state)" @click="killJob(job.id, index)" :label="$t('audience.segmentIntegrationJob.kill')" icon="pi pi-times-circle" iconPos="left" class="p-button-sm p-button-danger shadow" />
                    <Tag v-else-if="job.kill" class="bg-red-100 text-red-700 font-normal text-[0.80rem]" :value="$t('audience.segmentIntegrationJob.killSuccess')" icon="pi pi-info-circle" />
                    <Tag v-else class="bg-gray-100 text-gray-700 font-normal text-[0.80rem]" :value="$t(`integrations.job.states.FINISHED`)" icon="pi pi-info-circle" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </BlockUI>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button"
import axiosClient from "@/config/AxiosClient"
import PaginationSelect from "@/components/PaginationSelect"
import BlockUI from "primevue/blockui"
import BlockUILoader from "@/components/UI/BlockUILoader"
import InputText from 'primevue/inputtext'
import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';
import Tag from "primevue/tag"

export default {
  name: "SegmentIntegrationJobList",
  components: {
    Button,
    PaginationSelect,
    BlockUI,
    BlockUILoader,
    InputText,
    MultiSelect,
    Tag,
    Calendar
  },
  props: {
    segment: {
      type: Object,
      default: () => {},
    }
  },
  data(){
    return {
      segmentIntegrationJobs: [],
      pagination: {
        current_page: 1,
        from: 1,
        to: 2,
        last_page: 0,
        per_page: 20,
        path: "",
        total: 0,
      },
      search: {
        text: '',
        dates: [],
        channelSelect: [],
        segmentSelect: [],
      },
      optionOrdersBy: [
        { name: 'testg', code: 'latest' },
        { name: 'test2', code: 'oldest' },
        { name: 'test3', code: 'name' },
      ],
      orderSelected: {},
      loadings: {
        general: true,
        reloadJob: false
      },
      showOrderByDropdown: false,
      channels: [],
      segments: [],
    }
  },
  methods: {
    async getSegmentIntegrationJobs(reload=false){
      this.loadings.general = true
      if(reload) this.loadings.reloadJob = true
      let params = {page: this.pagination.current_page}
      if(this.search.text){
        params.search = this.search.text
      }
      if(this.search.dates.length){
        let dates = this.search.dates.filter(date => date != null).map(date => this.$moment(date).format("YYYY-MM-DD"))
        params.created_at = dates
      }
      if(this.search.channelSelect.length){
        params.channels = this.search.channelSelect
      }
      if(this.search.segmentSelect.length){
        params.segments = this.search.segmentSelect
      }
      let response = !this.segment.id ? await axiosClient.get(`api/2.0/project/1/segment/integration/jobs`, {params}) : await axiosClient.get(`api/2.0/project/1/segment/integration/jobs/${this.segment.id}`, {params})
      this.segmentIntegrationJobs = response.data.data
      this.pagination.current_page = response.data.current_page
      this.pagination.from = response.data.from
      this.pagination.to = response.data.to
      this.pagination.last_page = response.data.last_page
      this.pagination.per_page = response.data.per_page
      this.pagination.path = response.data.path
      this.pagination.total = response.data.total
      this.loadings.general = false
      if(reload) this.loadings.reloadJob = false
    },
    async paginationChange(page) {
      this.loadings.general = true
      this.pagination.current_page = page
      await this.getSegmentIntegrationJobs()
    },
    formatTime(seconds) {
      const intervals = [
        { name: "h", duration: 3600 },
        { name: "m", duration: 60 },
        { name: "s", duration: 1 }
      ];

      let result = "Remains ";

      intervals.forEach(function(interval) {
        const count = Math.floor(seconds / interval.duration);
        seconds -= count * interval.duration;
        if (count > 0) {
          result += `${count}${interval.name}`;
        }
      });

      return result.trim();
    },
    resetSearch(){
      this.search.text = ''
      this.search.dates = []
      this.search.channelSelect = []
      this.search.segmentSelect = []
      this.getSegmentIntegrationJobs()
    },
    async killJob(segmentIntegrationJobId, index){
      await axiosClient.get(`api/2.0/segment/integration/job/${segmentIntegrationJobId}/kill`)
      this.segmentIntegrationJobs[index].kill = true
      this.$toast.success(this.$t("audience.segmentIntegrationJob.killSuccess"))
    }
  },
  async created(){
    await this.getSegmentIntegrationJobs()
    if(!this.$store.state.Channels.channels.length){
      await this.$store.dispatch("Channels/getChannels")
    }
    if(!this.$store.state.Audience.segmentAudience.length){
      await this.$store.dispatch("Audience/getSegments", {currentProject: this.$route.params.id})
    }
    this.channels = this.$store.state.Channels.channels.filter((f) => f.services_segment.length > 0)
    this.segments = this.$store.state.Audience.segmentAudience
  }
}
</script>