<template>
  <div>
    <div class="bg-white border-t border-b border-gray-200">
      <div class="container-xl px-4 md:px-2 py-4 h-20 md:h-20 mx-auto flex flex-row items-center justify-between">
        <!--Left Filter-->
        <div class="flex flex-row items-center justify-between md:justify-start">
          <h4 class="text-xl font-semibold leading-tight text-gray-800 flex items-center mr-0 md:mr-5">
            <span>{{ t("audience.audience", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
          </h4>
        </div>
        <!--Right Filter-->
        <div class="flex md:flex-row items-center">
          <div class="my-3 flex flex-row space-x-2">
            <Button v-if="$verifyFeature($store.state.Projects.activeProject, 'segment_integration_job') && $verifyPermission(permissions, ['v2.client.segment.jobs'])" @click="segmentIntegrationJob" :label="$t('audience.segmentIntegrationJob.title')" icon="pi pi-note" iconPos="right" type="button" class="p-button-light shadow" />
            <Button @click="toAllProfiles" :label="$t('audience.profiles')" icon="pi pi-users" iconPos="right" type="button" class="p-button-light shadow" />
            <Button v-if="$verifyPermission(permissions, ['v2.client.project.segment.store'])" @click="OpenNewSegmentModal" :label="$t('audience.new')" icon="pi pi-plus" iconPos="right" type="button" class="p-button-success shadow" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import Button from "primevue/button"
import { mapGetters } from 'vuex'

export default {
  name: "WrapperFilter",
  components: {
    Button
  },
  props: [],
  computed: {
    ...mapGetters({permissions: 'Projects/permissionsForProject'})
  },
  data: function () {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      show: false
    }
  },
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  methods: {
    search: function () {
      this.$emit("Filter-wrapper", this.filterKeyword)
    },
    OpenNewSegmentModal: function () {
      // this.$store.dispatch('Menu/openNewSegmentModal')
      this.$emit("openModal")
    },
    toAllProfiles: function () {
      this.$router.push({
        name: "All Profiles",
        params: {
          id: this.currentProject ? this.currentProject : ""
        }
      })
    },
    OpenLibrariesModal: function () {
      this.$store.dispatch("Menu/openLibrariesModal")
    },
    segmentIntegrationJob() {
      this.$emit('openSegmentIntegrationJob')
    }
  }
}
</script>
