<template>
  <div class="w-full flex flex-col items-start">
    <div class="inline-flex w-full justify-between border-b-2 border-blue-100 bg-gray-50">
      <Title title="Dates" />
      <!--PERIOD-->
      <div class="inline-flex justify-end items-center w-full">
        <p class="text-gray-500 text-sm italic mr-4">
          From: <span class="text-gray-800 font-semibold">{{ $moment(timeline.start).format("YY-MM-DD") }}</span>
        </p>
        <p class="text-gray-500 text-sm italic mr-4">
          To: <span class="text-gray-800 font-semibold">{{ $moment(timeline.end).format("YY-MM-DD") }}</span>
        </p>
      </div>
    </div>
    <div class="lg:flex lg:flex-col lg:justify-start lg:items-center space-y-6 lg:space-y-0 h-full w-full">
      <div class="grid grid-cols-12 gap-4 w-full">
        <!--CHANNELS-->
        <div class="col-span-12 lg:col-span-6 my-1 lg:my-0">
          <label for="select_channel" class="inline-flex items-center cursor-pointer">
            <span class="label">Channel</span>
          </label>
          <div class="w-full relative" v-click-away="closeSelectChannels">
            <button id="select_channel" class="btn-xs btn-select" @click="$store.state.Menu.isChannelsOptionsOpen = !$store.state.Menu.isChannelsOptionsOpen">
              <span>{{ isChanelSelected == "" ? "All" : isChanelSelected }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down w-5 h-5 ml-4 text-blue-500" width="28" height="28" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="6 9 12 15 18 9" />
              </svg>
            </button>
            <!-- Dropdown menu -->
            <TransitionFade>
              <ul class="w-full absolute overflow-y-auto left-0 mt-2 bg-white dark:bg-gray-800 rounded-lg shadow-xl z-20 border divide-y divide-gray-100" v-if="$store.state.Menu.isChannelsOptionsOpen">
                <li class="h-14 w-full text-sm flex flex-col items-start justify-center cursor-pointer hover:bg-gray-50 transition ease-out duration-300" v-for="(c, i) in channels" :key="i" @click="selectChannel(c)">
                  <span class="font-semibold text-gray-500 inline-block px-2">{{ c == "" ? "All" : c }}</span>
                </li>
              </ul>
            </TransitionFade>
          </div>
        </div>

        <!--DATES-->
        <div class="col-span-12 lg:col-span-6 my-1 lg:my-0">
          <label for="select_channel" c class="inline-flex items-center cursor-pointer">
            <span class="label">Dates</span>
          </label>
          <div class="w-full relative">
            <button id="select_channel" class="btn-xs btn-select" @click="$store.state.Menu.isDatesOptionsOpen = !$store.state.Menu.isDatesOptionsOpen">
              <span>{{ isDatesSelected }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down w-5 h-5 ml-4 text-blue-500" width="28" height="28" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="6 9 12 15 18 9" />
              </svg>
            </button>
            <!-- Dropdown menu -->
            <TransitionFade>
              <ul class="w-full absolute overflow-y-auto left-0 mt-2 bg-white dark:bg-gray-800 rounded-lg shadow-xl z-20 border divide-y divide-gray-100" v-if="$store.state.Menu.isDatesOptionsOpen">
                <li class="h-14 w-full text-sm flex flex-col items-start justify-center cursor-pointer hover:bg-gray-50 transition ease-out duration-300" v-for="(date, i) in dates" :key="i" @click="selectDates(date)">
                  <span class="font-semibold text-gray-500 inline-block px-2">{{ date }}</span>
                </li>
              </ul>
            </TransitionFade>
            <!--TIMEOUT-->
            <div class="w-full z-40 lg:relative" v-show="(isDatesSelected == 'Number') & isActiveDatesSelected">
              <!-- <label class="block text-gray-600 dark:text-gray-200 text-sm font-medium mb-2">Timeout Function</label> -->
              <div class="w-full lg:w-96 bg-white border rounded-md p-4 space-y-8 shadow-xl lg:absolute mt-32 lg:mt-0 lg:top-2 lg:left-80">
                <div class="flex flex-row items-center justify-between">
                  <p class="text-gray-500 font-semibold text-sm">Set timeout to calculate data</p>
                  <div class="rounded-md">
                    <button class="bg-gray-200 text-gray-800 h-7 w-6 focus:outline-none rounded-l-md font-semibold" @click="calculateDays('rest')">-</button>
                    <input v-model.number="timeout" class="h-7 w-8 focus:outline-none p-1 text-center border border-gray-200 text-gray-800" />
                    <button class="bg-gray-200 text-gray-800 h-7 w-6 focus:outline-none rounded-r-md font-semibold" @click="calculateDays('sum')">+</button>
                  </div>
                </div>
                <div class="flex flex-row items-center justify-between">
                  <button class="btn btn-xs btn-neutral" @click="cancelTimeout">Cancel</button>
                  <button class="btn btn-xs btn-secondary" @click="saveTimeout">Save</button>
                </div>
              </div>
            </div>
            <!--DATEPICKER-->
            <div class="w-full z-40 lg:relative" v-show="(isDatesSelected == 'Datepicker') & isActiveDatesSelected">
              <!-- <label class="block text-gray-600 dark:text-gray-200 text-sm font-medium mb-2">Datepicker</label> -->
              <div class="w-full lg:w-96 bg-white border rounded-md p-4 space-y-8 shadow-xl lg:absolute mt-32 lg:mt-0 lg:top-2 lg:left-80">
                <div>
                  <p class="text-gray-500 font-semibold text-sm mb-2">Set timeout to calculate data</p>
                  <div class="w-full flex flex-row items-center justify-between gap-3">
                    <div class="w-24 relative" @mouseleave="setMenuShortCutClose">
                      <div class="rounded-lg shadow bg-gray-100 px-2 py-3 text-center text-gray-600 text-sm cursor-pointer" @mouseover="setMenuShortCutOpen">
                        <span>{{ isPlaceHolder }} days</span>
                      </div>
                      <!-- Dropdown menu -->
                      <TransitionFade>
                        <div class="absolute pt-2 overflow-y-auto -left-0 shadow-lg z-100 rounded-lg" v-if="$store.state.Calendar.isMenuShortCutsOpen">
                          <ul class="dark:bg-gray-800 rounded-lg divide-y divide-gray-100 bg-white border">
                            <li class="h-10 w-24 text-sm flex flex-col items-start justify-center cursor-pointer hover:bg-gray-50 transition ease-out duration-300" :class="[$store.state.Calendar.isShortCutData.length - 1 == i ? 'rounded-b-lg' : '' || i == 0 ? 'rounded-t-lg' : '']" v-for="(sc, i) in $store.state.Calendar.isShortCutData" :key="i" @click="selectShortCutDates(sc)">
                              <span class="font-semibold text-gray-500 inline-block px-4">{{ sc }} Days</span>
                            </li>
                          </ul>
                        </div>
                      </TransitionFade>
                    </div>
                    <div>
                      <v-date-picker show-weeknumbers="right-outside" v-model="timeline" is-range :masks="masks">
                        <template v-slot="{ inputValue, inputEvents }">
                          <div class="shadow w-auto flex justify-center items-center rounded-lg bg-gray-100 px-2 py-3" v-on="inputEvents.end">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-5 h-5 mx-1 text-gray-600">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                            </svg>
                            <input v-model="inputValue.start" class="block w-24 bg-gray-100 rounded-l-lg focus:outline-none text-sm text-center text-gray-600" />
                            <input :value="inputValue.end" class="block w-24 bg-gray-100 rounded-r-lg focus:outline-none text-sm text-center text-gray-600" />
                          </div>
                        </template>
                      </v-date-picker>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row items-center justify-between">
                  <button class="btn btn-xs btn-neutral" @click="cancelTimeout">Cancel</button>
                  <button class="btn btn-xs btn-secondary" @click="saveTimeout">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TransitionFade from "@/components/TransitionFade"
import Title from "@/components/Audience/Basic/Title"

export default {
  components: {
    TransitionFade,
    Title
  },
  data() {
    return {
      isChanelSelected: "Select",
      channels: ["online", "offline", "", "omnichannel"],
      isDatesSelected: "Select",
      dates: ["Datepicker", "Number"],
      timeout: 0,
      masks: {
        input: "YYYY-MM-DD"
      },
      timeline: {
        start: this.$store.state.Calendar.timeline.date_start,
        end: this.$store.state.Calendar.timeline.date_end
      },
      isPlaceHolder: 30,
      isActiveDatesSelected: false
    }
  },
  computed: {},
  methods: {
    closeSelectChannels: function () {
      this.$store.dispatch("Menu/closeSelectChannels")
    },
    selectChannel(val) {
      this.isChanelSelected = val
      this.$store.dispatch("BasicSegment/selectChannels", val)
      this.closeSelectChannels()
    },
    closeSelectDates: function () {
      this.isActiveDatesSelected = false
      this.$store.dispatch("Menu/closeSelectDates")
    },
    selectDates: function (val) {
      this.isDatesSelected = val
      this.isActiveDatesSelected = true
    },
    cancelTimeout: function () {
      this.isDatesSelected = "Select"
      this.$store.dispatch("Calendar/resetTimeLine")
      this.closeSelectDates()
      this.timeout = 0
    },
    saveTimeout: function () {
      this.$store.dispatch("BasicSegment/selectDates", this.timeline)
      this.closeSelectDates()
      this.timeout = 0
    },
    calculateDays: function (val) {
      if (val == "sum") this.timeout++
      if (val == "rest") {
        if (this.timeout == 0) return
        this.timeout--
      }
      this.timeline = {
        start: this.$moment(new Date()).subtract(this.timeout, "days").format("YYYY-MM-DD 00:00:00"),
        end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
      }
    },
    setMenuShortCutOpen: function () {
      this.$store.dispatch("Calendar/openMenuShortCut")
    },
    setMenuShortCutClose: function () {
      this.$store.dispatch("Calendar/closeMenuShortCut")
    },
    selectShortCutDates: function (payload) {
      this.setMenuShortCutClose()
      this.isPlaceHolder = payload
      switch (payload) {
        case 7:
          this.timeline = {
            start: this.$moment(new Date()).subtract(7, "days").format("YYYY-MM-DD 00:00:00"),
            end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
          }
          break
        case 14:
          this.timeline = {
            start: this.$moment(new Date()).subtract(14, "days").format("YYYY-MM-DD 00:00:00"),
            end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
          }
          break

        case 30:
          this.timeline = {
            start: this.$moment(new Date()).subtract(30, "days").format("YYYY-MM-DD 00:00:00"),
            end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
          }
          break
        case 60:
          this.timeline = {
            start: this.$moment(new Date()).subtract(60, "days").format("YYYY-MM-DD 00:00:00"),
            end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
          }
          break
        case 90:
          this.timeline = {
            start: this.$moment(new Date()).subtract(90, "days").format("YYYY-MM-DD 00:00:00"),
            end: this.$moment(new Date()).format("YYYY-MM-DD 23:59:59")
          }
          break
        default:
          this.timeline = {
            start: this.$store.state.Calendar.timeline.date_start,
            end: this.$store.state.Calendar.timeline.date_end
          }
          break
      }
    }
  }
}
</script>
