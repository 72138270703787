<template>
  <div @click="select(type)" class="w-7/12 xl:w-3/5 h-44 rounded-lg border border-gray-200 shadow-md transition-all duration-300 ease-out flex flex-row justify-center items-center p-4" :class="[['custom', 'basic', 'code'].indexOf(type) > -1 && $store.state.Projects.activeProject.plan == 'freemium' ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer hover:bg-gray-50 hover:border-300 hover:shadow-md']">
    <div class="w-3/6 lg:w-4/12 h-44 flex items-center">
      <svg xmlns="http://www.w3.org/2000/svg" v-if="type == 'library'" class="icon icon-tabler icon-tabler-layout-list w-24 h-24 mx-auto" viewBox="0 0 24 24" stroke-width="1.5" stroke="#597e8d" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <rect x="4" y="4" width="16" height="6" rx="2" />
        <rect x="4" y="14" width="16" height="6" rx="2" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" v-if="type == 'custom'" class="icon icon-tabler icon-tabler-chart-arcs w-24 h-24 mx-auto" width="60" height="60" viewBox="0 0 24 24" stroke-width="2" stroke="#597e8d" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <circle cx="12" cy="12" r="1" />
        <path d="M16.924 11.132a5 5 0 1 0 -4.056 5.792" />
        <path d="M3 12a9 9 0 1 0 9 -9" />
      </svg>
      <svg v-if="type == 'basic'" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-box w-24 h-24 mx-auto" width="44" height="44" viewBox="0 0 24 24" stroke-width="2" stroke="#597e8d" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3" />
        <line x1="12" y1="12" x2="20" y2="7.5" />
        <line x1="12" y1="12" x2="12" y2="21" />
        <line x1="12" y1="12" x2="4" y2="7.5" />
      </svg>
      <svg v-if="type == 'code'" class="icon icon-tabler icon-tabler-box w-24 h-24 mx-auto" viewBox="0 -15 40 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.1746 43.9571L14.9812 42.4359C14.5412 42.2812 14.2937 41.7054 14.4175 41.1554L23.8011 0.744518C23.9249 0.194476 24.3854 -0.114922 24.8254 0.0397771L29.0188 1.56099C29.4588 1.71568 29.7063 2.29151 29.5825 2.84155L20.1989 43.2524C20.0683 43.8024 19.6146 44.1204 19.1746 43.9571V43.9571ZM11.3377 34.3142L14.3281 30.3264C14.6443 29.9053 14.6237 29.2349 14.2731 28.8482L8.04484 21.9985L14.2731 15.1487C14.6237 14.762 14.6512 14.0916 14.3281 13.6705L11.3377 9.68269C11.0284 9.27016 10.5059 9.24438 10.169 9.63972L0.262948 21.2422C-0.0876494 21.6461 -0.0876494 22.3422 0.262948 22.7462L10.169 34.3572C10.5059 34.7525 11.0284 34.7354 11.3377 34.3142V34.3142ZM33.831 34.3658L43.7371 22.7548C44.0877 22.3508 44.0877 21.6547 43.7371 21.2507L33.831 9.63112C33.501 9.24438 32.9785 9.26156 32.6623 9.6741L29.6719 13.6619C29.3557 14.083 29.3763 14.7534 29.7269 15.1401L35.9552 21.9985L29.7269 28.8482C29.3763 29.2349 29.3488 29.9053 29.6719 30.3264L32.6623 34.3142C32.9716 34.7354 33.4941 34.7525 33.831 34.3658V34.3658Z"
          fill="#597e8d"
        />
      </svg>
    </div>
    <div class="w-3/6 lg:w-full h-1/2 flex flex-col justify-center items-start">
      <h4 class="text-sm md:text-lg 2xl:text-lg text-gray-800 font-semibold uppercase">{{ title }}</h4>
      <h4 class="text-sm md:text-xs 2xl:text-base max-w-sm text-gray-500 font-normal">{{ subtitle }}</h4>

      <Tag v-if="['custom', 'basic', 'code'].indexOf(type) > -1 && $store.state.Projects.activeProject.plan == 'freemium'" class="my-2 bg-indigo-100 text-indigo-700 font-normal" :value="$t('You need a paid plan to use this feature.')" icon="pi pi-info-circle" />
    </div>
  </div>
</template>
<script>
import Tag from "primevue/tag"

export default {
  props: ["title", "subtitle", "type"],
  emits: ["TypeSelected"],
  components: {
    Tag
  },
  methods: {
    select: function (type) {
      this.$emit("TypeSelected", type)
    }
  }
}
</script>
