<template>
  <div class="w-full mx-auto rounded-lg flex flex-row bg-white border border-gray-200 h-60 shadow-md">
    <div class="w-full p-4 space-y-2">
      <div class="flex items-start justify-between h-10 overflow-ellipsis">
        <p class="text-gray-500 font-semibold uppercase" :class="[item.name.length > 60 ? 'text-xs' : 'text-sm']">{{ item.name }}</p>
        <Dropdown>
          <template v-slot:buttons>
            <a @click="toSegment(item.id)" href="javascript:void(0);" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800 group" role="menuitem"> <DropdownOptionShow /> {{ t("audience.see", {}, { locale: $store.state.Langs.selected.lang }) }}</a>
            <a @click="OpenNewSegmentModal(item)" href="javascript:void(0);" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800 group" role="menuitem"> <DropdownOptionEdit /> {{ t("audience.edit", {}, { locale: $store.state.Langs.selected.lang }) }}</a>
            <a v-if="item.data_json.mailup_sync" @click="syncSegment(item)" href="javascript:void(0);" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-green-100 hover:text-green-800 group" role="menuitem"> <DropdownOptionSync /> Sync</a>
            <a @click="deleteSegment(item)" href="javascript:void(0);" class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-red-100 hover:text-red-800 group" role="menuitem"> <DropdownOptionDelete /> {{ t("audience.delete", {}, { locale: $store.state.Langs.selected.lang }) }}</a>
          </template>
        </Dropdown>
      </div>
      <div class="h-6">
        <div v-if="item.data_json.mailup_sync">
          <small class="text-green-800 text-xs font-semibold bg-green-100 px-2 py-1 rounded-full">Sincronizacion #{{ item.data_json.mailup_groups[0].id }}</small>
        </div>
      </div>
      <div class="w-full grid grid-cols-12 px-3 card-dashed space-y-4 h-auto">
        <div class="col-span-12">
          <div class="flex flex-row items-center">
            <div class="w-1/2 text-left">
              <p class="text-gray-500 uppercase font-semibold text-xs">{{ t("audience.revenue", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
              <span class="text-gray-800 font-semibold w-full truncate">{{ $filters.currency(item.cache.revenue ? item.cache.revenue : 0) }} </span>
            </div>
            <div class="w-1/2 text-left">
              <p class="text-gray-500 uppercase font-semibold text-xs">{{ t("audience.average", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
              <span class="text-gray-800 font-semibold w-full truncate">{{ $filters.currency(item.cache.revenue_avg ? item.cache.revenue_avg : 0) }}</span>
            </div>
          </div>
        </div>
        <div class="col-span-12">
          <div class="flex flex-row items-center">
            <div class="w-1/2 text-left">
              <p class="text-gray-500 uppercase font-semibold text-xs">{{ t("audience.transactions", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
              <span class="text-gray-800 font-semibold w-full truncate">{{ $filters.number(item.cache.conversions ? item.cache.conversions : 0) }} </span>
            </div>
            <div class="w-1/2 text-left">
              <p class="text-gray-500 uppercase font-semibold text-xs">{{ t("audience.profiles", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
              <span class="text-gray-800 font-semibold w-full truncate">{{ $filters.number(item.cache.profiles ? item.cache.profiles : 0) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import Dropdown from "@/components/Dropdowns/Dropdown"
import DropdownOptionDelete from "@/components/Dropdowns/DropdownOptionDelete"
import DropdownOptionEdit from "@/components/Dropdowns/DropdownOptionEdit"
import DropdownOptionShow from "@/components/Dropdowns/DropdownOptionShow"
import DropdownOptionSync from "@/components/Dropdowns/DropdownOptionSync"

export default {
  name: "SegmentCard",
  props: ["item"],
  emits: ["deleteSegment", "toSegment", "OpenNewSegmentModal", "syncSegment"],
  components: {
    Dropdown,
    DropdownOptionDelete,
    DropdownOptionEdit,
    DropdownOptionShow,
    DropdownOptionSync
  },
  data: function () {
    return {}
  },
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  computed: {},
  methods: {
    deleteSegment: function (id) {
      this.$emit("deleteSegment", id)
    },
    toSegment: function (id) {
      this.$emit("toSegment", id)
    },
    OpenNewSegmentModal: function (item) {
      this.$emit("OpenNewSegmentModal", item)
    },
    syncSegment: function (item) {
      this.$emit("syncSegment", item)
    }
  }
}
</script>
