<template>
  <div>
    <NewSegmentModal v-if="$verifyPermission(permissions, ['v2.client.project.segment.store']) && openModal"
                     @simulateSegment="simulateSegment" :errorMessage="errorMessage" :error="error"
                     :updatingSegment="updatingSegment" @refreshList="getSegments" @refreshSegments="getSegments()"
                     @storeLibrary="storeLibrary" :openModal="openModal" @closeModal="openModal = false" />
    <WrapperAudience @Filter-wrapper="onFilterWrapper" @openModal="openModal = true"
                     @openSegmentIntegrationJob="openSegmentIntegrationJob" />
    <div>
      <TransitionFade>
        <div v-if="!currentProject">
          <NoContent />
        </div>
      </TransitionFade>
      <div v-show="modal.segmentIntegration.segment">
        <div class="container-xl px-2 py-2 mx-auto w-full my-4 flex flex-col">
          <!-- desktop table -->
          <div class="mx-auto py-5">
            <div class="grid grid-cols-6 md:grid-cols-6 gap-4 w-full items-center md:px-0">
              <div>
                <span class="p-input-icon-left w-full">
                  <i class="pi pi-search" />
                  <InputText
                    type="text"
                    v-model="search.text"
                    v-debounce:700ms="getSegments"
                    class="p-inputtext-sm shadow-sm w-full"
                    autocomplete="off"
                    :placeholder="$t('audience.search')"
                  />
                </span>
              </div>
              <div>
                <span class="p-input-icon-left w-full">
                  <i class="pi pi-search" />
                  <MultiSelect
                    v-model="search.channelSelect"
                    :placeholder="$t('navbar.channels')"
                    :options="channels"
                    :filter="true"
                    class="w-full p-multiselect-icon leading-3"
                    optionValue="id"
                    optionLabel="name"
                    @change="getSegments"
                  />
                </span>
              </div>

              <div>
                <span class="p-input-icon-left w-full">
                  <i class="pi pi-search" />
                  <MultiSelect
                    v-model="search.tagsSelect"
                    :placeholder="$t('navbar.tags')"
                    :options="tags"
                    :filter="true"
                    class="w-full p-multiselect-icon leading-3"
                    optionValue="id"
                    optionLabel="name"
                    @change="getSegments"
                  />
                </span>
              </div>

              <div class="relative col-span-3 flex flex-row gap-6">
                <p
                  class="text-sm font-semibold text-slate-500 hover:text-slate-700 cursor-pointer"
                  @click="resetSearch"
                  v-if="search.text != '' || search.channelSelect.length > 0"
                >
                  {{ $t("clearFilters") }}
                </p>

                <div class="relative flex flex-1 self-end justify-end">
                  <div class="relative">
                    <DropdownPrimevue
                      ref="orderByDropdown"
                      class="hidden-dropdown"
                      input-class="p-0"
                      v-model="orderSelected"
                      :options="optionOrdersBy"
                      @change="getSegments() && (showOrderByDropdown = false)"
                      optionLabel="name"
                      @show="showOrderByDropdown = true"
                      @hide="showOrderByDropdown = false"
                    />

                    <p
                      class="flex flex-row gap-2 items-center text-sm font-semibold text-slate-500 hover:text-slate-700 cursor-pointer select-none"
                      @click.stop="toggleDropdownOrderBy"
                    >
                      {{ $t("audience.order." + orderSelected.code) }}

                      <ChevronUpIcon
                        class="w-5 h-5"
                        v-if="showOrderByDropdown"
                      />
                      <ChevronDownIcon
                        class="w-5 h-5"
                        v-else
                      />
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div v-if="!loadings.general" class="flex flex-col items-center mb-2 w-full">
            <PaginationSelect :current_page="pagination.current_page" :from="pagination.from"
                              :last_page="pagination.last_page" :path="pagination.path" :per_page="pagination.per_page"
                              :to="pagination.to" :total="pagination.total" :dropdown="true"
                              @change-page="paginationChange" />
          </div>
          <AudienceSkeleton v-if="loadings.general" />
          <!-- mobile grid cards -->
          <ul v-if="!loadings.general" class="w-full mb-20 lg:hidden">
            <li class="w-full grid grid-cols-12 gap-4" v-if="segments.length">
              <div class="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-3"
                   v-for="item in segments" :key="item.id">
                <SegmentCard :item="item" @deleteSegment="deleteSegment" @toSegment="toSegment"
                             @OpenNewSegmentModal="OpenNewSegmentModal" @syncSegment="syncSegment" />
              </div>
            </li>
          </ul>
          <ul v-if="!loadings.general" class="w-full mb-20 hidden lg:inline-block">
            <div class="-my-2 sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8" v-if="segments.length > 0">
                <div class="border border-gray-200 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                    <tr>
                      <th scope="col"
                          class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg">
                        <div class="flex gap-2 cursor-pointer justify-start">
                          <span>{{ $t("audience.name", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                        </div>
                      </th>
                      <th scope="col"
                          class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                        <div class="flex gap-2 cursor-pointer justify-start">
                          <span>{{ $t("cardProject.integrations") }}</span>
                        </div>
                      </th>
                      <th scope="col"
                          class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                        <div class="flex gap-2 cursor-pointer justify-start">
                          <span>{{ $t("audience.profiles", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                        </div>
                      </th>
                      <th scope="col"
                          class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                        <div class="flex gap-2 cursor-pointer justify-start">
                          <span>{{ $t("audience.revenue", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                        </div>
                      </th>
                      <th scope="col"
                          class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                        <div class="flex gap-2 cursor-pointer justify-start">
                          <span>{{ $t("audience.transactions", {}, { locale: $store.state.Langs.selected.lang })
                            }}</span>
                        </div>
                      </th>
                      <th scope="col"
                          class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
                        <div class="flex gap-2 cursor-pointer justify-start">
                          <span>{{ $t("audience.average", {}, { locale: $store.state.Langs.selected.lang }) }}</span>
                        </div>
                      </th>
                      <th scope="col"
                          class="px-4 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-t-lg"></th>
                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-100">
                    <tr v-for="(segment, i) in segments" :key="segment.id"
                        class="bg-white hover:bg-gray-100 transition-all ease-out duration-500 border- border-gray-100">
                      <td class="px-4 text-left py-4 whitespace-wrap text-sm text-gray-500"
                          :class="[i == segments.length - 1 ? 'rounded-b-lg' : '']">
                        <div class="font-medium text-gray-800 flex flex-row">{{ segment.name }}</div>
                        <div class="font-medium text-gray-400 flex flex-row text-xs">ID: {{segment.id}}</div>
                      </td>
                      <td class="px-4 py-4">
                        <div class="flex flex-row">
                          <div v-if="!segment.channels.length" class="text-gray-500">
                            <!--{{ $t('audience.notIntegrations') }}--></div>
                          <img v-for="channel in segment.channels" :key="channel.id" :src="channel.avatar_short"
                               class="w-8 h-8 cursor-pointer" :class="[channel.is_desactive ? 'opacity-10' : '']"
                               v-tooltip.top="channel.is_desactive ? `${channel.name} paused` : channel.name"
                               @click="openSegmentIntegrationList(segment, channel)" />
                        </div>
                      </td>
                      <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
                        <div class="text-gray-500">
                          {{ $filters.number(segment.cache.profiles ? segment.cache.profiles : 0) }}
                        </div>
                      </td>
                      <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
                        <div class="text-gray-500">
                          {{ $filters.currency(segment.cache.revenue ? segment.cache.revenue : 0) }}
                        </div>
                      </td>
                      <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
                        <div class="text-gray-500">
                          {{ $filters.number(segment.cache.conversions ? segment.cache.conversions : 0) }}
                        </div>
                      </td>
                      <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
                        <div class="text-gray-500">
                          {{ $filters.currency(segment.cache.revenue_avg ? segment.cache.revenue_avg : 0) }}
                        </div>
                      </td>
                      <td class="px-4 text-right py-4" :class="[i == segments.length - 1 ? 'rounded-b-lg' : '']">
                        <Dropdown>
                          <template v-slot:buttons>
                            <button @click="toSegment(segment.id)" href="javascript:void(0);"
                                    class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800 group"
                                    role="menuitem">
                              <DropdownOptionShow />
                              {{ $t("audience.see", {}, { locale: $store.state.Langs.selected.lang }) }}
                            </button>
                            <button @click="OpenNewSegmentModal(segment)" href="javascript:void(0);"
                                    class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800 group"
                                    role="menuitem">
                              <DropdownOptionEdit />
                              {{ $t("audience.edit", {}, { locale: $store.state.Langs.selected.lang }) }}
                            </button>
                            <a v-if="segment.data_json.mailup_sync" @click="syncSegment(segment)"
                               href="javascript:void(0);"
                               class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800 group"
                               role="menuitem">
                              <DropdownOptionSync />
                              Sync </a>
                            <a @click="duplicateSegment(segment.id)" href="javascript:void(0);"
                               class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800 group"
                               role="menuitem">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                   stroke="currentColor" class="w-6 stroke-current">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                              </svg>
                              {{ $t("audience.duplicate", {}, { locale: $store.state.Langs.selected.lang }) }}
                            </a>
                            <a @click.stop="calculateSegment(segment)" href="javascript:void(0);"
                               class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800 group"
                               role="menuitem">
                              <Icon icon="tabler:calculator" class="w-6 h-6" />
                              {{ $t("audience.calculate") }} </a>
                            <a v-if="$route.query.jobs" @click.stop="openSegmentIntegrationJob(segment)"
                               href="javascript:void(0);"
                               class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-gray-100 hover:text-gray-800 group"
                               role="menuitem">
                              <Icon icon="tabler:calculator" class="w-6 h-6" />
                              {{ $t("audience.segmentIntegrationJob.title") }} </a>
                            <a @click.stop="deleteSegment(segment)" href="javascript:void(0);"
                               class="cursor-pointer flex gap-2 items-center w-full px-2 py-2 text-sm transition duration-150 rounded-md text-gray-600 hover:bg-red-100 hover:text-red-800 group"
                               role="menuitem">
                              <DropdownOptionDelete />
                              {{ $t("audience.delete") }} </a>
                          </template>
                        </Dropdown>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <li v-if="!$store.state.Audience.loading && segments.length === 0 && !hasFilter"
                class="text-center block mx-auto my-20">
              <EmptyState @openModalCreateSegment="openModal = true" />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Dialog :header="$t('audience.synchronizations') + modal.segmentIntegration.channel.name"
            v-model:visible="modal.segmentIntegration.show" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '70vw' }" :modal="true">
      <SegmentIntegrationList :segment="modal.segmentIntegration.segment" :channel="modal.segmentIntegration.channel"
                              @refreshSegments="getSegments"></SegmentIntegrationList>
    </Dialog>
    <Dialog :header="$t('audience.segmentIntegrationJob.title')" v-model:visible="modal.segmentIntegrationJob.show"
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '90vw' }" :modal="true">
      <SegmentIntegrationJobList :segment="modal.segmentIntegrationJob.segment"></SegmentIntegrationJobList>
    </Dialog>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import TransitionFade from "@/components/TransitionFade"
import SegmentCard from "@/components/Audience/SegmentCard"
import WrapperAudience from "@/components/Audience/WrapperAudience"
import NoContent from "@/components/Utils/NoContent"
import EmptyState from "@/components/Audience/List/EmptyState"
import NewSegmentModal from "@/components/Audience/NewSegmentModal"
import Dropdown from "@/components/Dropdowns/Dropdown"
import DropdownOptionDelete from "@/components/Dropdowns/DropdownOptionDelete"
import DropdownOptionEdit from "@/components/Dropdowns/DropdownOptionEdit"
import DropdownOptionShow from "@/components/Dropdowns/DropdownOptionShow"
import DropdownOptionSync from "@/components/Dropdowns/DropdownOptionSync"
import AudienceSkeleton from "@/components/Audience/AudienceSkeleton"
import axiosClient from "@/config/AxiosClient"
import PaginationSelect from "@/components/PaginationSelect"
import { mapGetters } from "vuex"
import SegmentIntegrationList from "@/components/Audience/SegmentIntegration/SegmentIntegrationList"
import SegmentIntegrationJobList from "@/components/Audience/SegmentIntegration/SegmentIntegrationJobList"
import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"
import { Icon } from "@iconify/vue"
import Dialog from "primevue/dialog"
import InputText from "primevue/inputtext"
import MultiSelect from "primevue/multiselect"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/solid"
import DropdownPrimevue from "primevue/dropdown"

const sprintf = require("sprintf-js").sprintf

export default {
  components: {
    WrapperAudience,
    TransitionFade,
    SegmentCard,
    NoContent,
    NewSegmentModal,
    Dropdown,
    DropdownOptionDelete,
    DropdownOptionEdit,
    DropdownOptionShow,
    DropdownOptionSync,
    EmptyState,
    AudienceSkeleton,
    PaginationSelect,
    Icon,
    SegmentIntegrationList,
    Dialog,
    SegmentIntegrationJobList,
    InputText,
    MultiSelect,
    ChevronDownIcon,
    ChevronUpIcon,
    DropdownPrimevue
  },
  data: function() {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      filterKeyword: "",
      sort: "id,DESC",
      limit: 50,
      default: {
        sort: "id,DESC",
        limit: 50
      },
      error: false,
      errorMessage: "",
      updatingSegment: false,
      hasFilter: false,
      credentialId: {
        vtex: null,
        mailup: null
      },
      openModal: false,
      segments: [],
      modal: {
        segmentIntegration: {
          show: false,
          segment: {},
          channel: {}
        },
        segmentIntegrationJob: {
          show: false,
          segment: {}
        }
      },
      search: {
        text: "",
        channelSelect: [],
        tagsSelect: []
      },
      pagination: {
        current_page: 1,
        from: 1,
        to: 2,
        last_page: 0,
        per_page: 20,
        path: "",
        total: 0
      },
      channels: [],
      loadings: {
        general: false
      },
      orderSelected: {},
      optionOrdersBy: [],
      showOrderByDropdown: false,
      tags: []
    }
  },
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  async created() {
    this.optionOrdersBy = [
      { name: this.$t("latest"), code: "id,DESC" },
      { name: this.$t("oldest"), code: "id,ASC" },
      { name: this.$t("name"), code: "name,ASC" }
    ]
    this.orderSelected = this.optionOrdersBy[0]
    if (this.currentProject) await this.getSegments()
    if (!this.$store.state.Channels.channels.length) {
      await this.$store.dispatch("Channels/getChannels")
    }
    this.channels = this.$store.state.Channels.channels.filter((f) => f.services_segment.length > 0)
    this.searchTags()
  },
  computed: {
    ...mapGetters({ permissions: "Projects/permissionsForProject" }),
    getCollection() {
      return this.$store.state.Audience.collection
    },
    getAudienceKeys() {
      return this.$store.state.Audience.projectAudienceKeys
    }
  },
  methods: {
    sortAndRefresh(sort) {
      console.log(sort)
      this.sort = sort
      this.getSegments()
    },
    async searchTags() {
      let response = await axiosClient.get(sprintf(ApiRoute.tags.index) + "?project=" + this.currentProject)
      this.tags = response.data.data
    },

    OpenNewSegmentModal: async function(item) {
      if (item.data_json.vtex_sync != undefined) {
        let segmentIntegrationVtex = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.currentProject}/segment/integration?filter[project_credential.channel_id][%3D]=1&filter[segment_id][%3D]=${item.id}`)
        item.segmentIntegrationVtex = segmentIntegrationVtex.data.data[0]
      }
      if (item.data_json.mailup_sync != undefined) {
        let segmentIntegrationMailup = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/project/${this.currentProject}/segment/integration?filter[project_credential.channel_id][%3D]=2&filter[segment_id][%3D]=${item.id}`)
        item.segmentIntegrationMailup = segmentIntegrationMailup.data.data[0]
      }
      await this.$store.dispatch("Audience/updatingSegment", item)
      // this.$store.dispatch("Menu/openNewSegmentModal")
      this.openModal = true
    },

    createSegmentModal() {
      this.$store.dispatch("Menu/openNewSegmentModal")
    },

    onFilterWrapper: function(value) {
      if (value == "") this.hasFilter = false
      else if (value) this.hasFilter = true
      this.filterKeyword = value
      this.currentProject ? this.getSegments() : null
    },

    async getSegments() {
      this.closeModal = false
      this.loadings.general = true
      if (!this.getCollection.length) {
        await this.$store.dispatch("Audience/getCollection", this.currentProject)
      }

      if (!this.getAudienceKeys.length) {
        await this.$store.dispatch("Audience/getAudienceKeys", this.currentProject)
        await this.$store.dispatch("Audience/getAudienceKeysFilter", this.currentProject)
      }


      let params = {}
      params.page = this.pagination.current_page
      params.limit = this.default.limit
      params.include = "segment_integrations.project_credential.channel"
      params.sort = this.orderSelected.code


      if (this.search.text) {
        params.search = this.search.text
      }
      if (this.search.tagsSelect) {
        params.tags = this.search.tagsSelect
      }
      if (this.search.channelSelect.length) {
        params.channels = this.search.channelSelect
      }
      let response = await axiosClient.get(`api/2.0/projects/${this.currentProject}/segments`, { params })
      this.segments = response.data.data
      this.pagination = response.data.meta
      this.segments.forEach((segment) => {
        let channels = []
        segment.segment_integrations.forEach((segmentIntegration) => {
          if (!channels.map((channel) => channel.id).includes(segmentIntegration.project_credential.channel_id)) {
            channels.push({
              ...segmentIntegration.project_credential.channel,
              is_desactive: segment.segment_integrations.filter((f) => f.project_credential.channel_id == segmentIntegration.project_credential.channel_id).some((f) => f.is_active == 0)
            })
          }
        })
        segment.channels = channels
      })
      if (response.status == 403) {
        this.$toast.error(`${response.data.message}`)
        this.$router.push({
          name: "Projects",
          params: { id: "" }
        })
      }
      this.loadings.general = false
    },

    syncSegment: function({ id, name }) {
      let currentProject = this.currentProject
      let segment = {}
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, sync up!"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("Audience/syncSegment", { id, currentProject, segment }).then((res) => {
            if (res.status === 204) this.$toast.success(`Segment ${name} was synchronized successfully`)
            if (res.status === 500 || res.status === 401 || res.status === 422) this.$toast.error("Error, please try again in a few minutes")
          })
        }
      })
    },

    simulateSegment: function(segment) {
      let currentProject = this.currentProject
      currentProject ? this.$store.dispatch("Audience/simulateSegment", { currentProject, segment }) : null
    },

    deleteSegment: function({ id, name }) {
      let currentProject = this.currentProject
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("Audience/deleteSegment", { id, currentProject }).then((res) => {
            if (res.status === 204) this.getSegments(), this.$toast.success(`Segment ${name} was deleted successfully`)
            if (res.status === 500 || res.status === 401 || res.status === 422) this.$toast.error("Error, please try again in a few minutes")
          })
        }
      })
    },

    storeLibrary: function({ id }) {
      let currentProject = this.currentProject
      if (currentProject)
        this.$store.dispatch("Audience/storeLibrary", { currentProject, id }).then((res) => {
          this.$store.state.Audience.libraryAdded.push(id)
          if (res.status == 204 || res.status == 200) {
            this.getSegments()
            // this.$toast.success(`${name} successfully added`)
          }
          // this.$store.dispatch('Audience/resetSimulation')
        })
    },

    handleRes(res, type = "", name) {
      if (res.status == 500) this.$toast.error("Error, please try again in a few minutes")
      if (res.status == 422) {
        this.errorMessage = Object.keys(res.data.errors)
          .map(function(errorKey) {
            return res.data.errors[errorKey].join(",")
          })
          .join(",")
        this.error = true
      }
      if (res.status == 204 || res.status == 200) (this.error = false), this.$store.dispatch("Menu/closeNewSegmentModal"), this.$toast.success(`${name} ${type} successfully`), this.getSegments(), this.$store.dispatch("Audience/resetTypeSelected")
    },

    toSegment(segmentId) {
      this.$router.push({ name: "Segment Profiles", params: { id: this.currentProject, segmentId: segmentId } })
    },

    duplicateSegment: async function(segmentId) {
      let res = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/segment/${segmentId}/duplicate`)
      if (res.status == 200 || res.status == 204) {
        if (this.$store.state.Langs.selected.lang == "en") {
          this.$swal({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, sure"
          }).then((result) => {
            if (result.isConfirmed) {
              this.$toast.info(`Segment successfully duplicated`)
            }
          })
        } else {
          this.$swal({
            title: "¿Estás seguro?",
            text: "¡No podrás revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sí, claro",
            cancelButtonText: "No, cancelar"
          }).then((result) => {
            if (result.isConfirmed) {
              this.$toast.info(`Segmento duplicado exitosamente`)
            }
          })
        }
      }
      if (res.status == 422 || res.status == 500) {
        if (this.$store.state.Langs.selected.lang == "en") {
          this.$toast.error(`Error, please try again in a few minutes`)
        }
        if (this.$store.state.Langs.selected.lang == "es") {
          this.$toast.error(`Error, por favor, inténtelo de nuevo en unos minutos`)
        }
      }
    },

    paginationChange: function(page) {
      this.pagination.current_page = page
      this.getSegments()
    },

    async calculateSegment(segment) {
      this.$swal({
        text: "Esta tarea sucederá en segundo plano, para ver el resultado deberás recargar la página en unos minutos.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Calcular",
        cancelButtonText: "No, cancelar"
      }).then(async (result) => {
        if (result.isConfirmed) {
          await new FormClient().get(sprintf(ApiRoute.segment.calculate, { segment_id: segment.id }))
          this.$toast.success(this.$t("audience.calculateSuccess"))
        }
      })
    },

    openSegmentIntegrationList(segment, channel) {
      this.modal.segmentIntegration.segment = segment
      this.modal.segmentIntegration.channel = channel
      this.modal.segmentIntegration.show = true
    },
    openSegmentIntegrationJob(segment = null) {
      if (segment) {
        this.modal.segmentIntegrationJob.segment = segment
      }
      this.modal.segmentIntegrationJob.show = true
    },
    toggleDropdownOrderBy() {
      if (this.showOrderByDropdown) {
        this.showOrderByDropdown = false
        this.$refs.orderByDropdown.hide()
      } else {
        this.showOrderByDropdown = true
        this.$refs.orderByDropdown.show()
      }
    },
    resetSearch() {
      this.search.text = ""
      this.search.channelSelect = []
      this.getSegments()
    }
  }
}
</script>
