<template>
  <div class="w-full">
    <div class="flex flex-col">
      <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">{{ $t("audience.state")
                }}
              </th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                {{ $t("audience.frequency") }}
              </th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                {{ $t("audience.createDate") }}
              </th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                {{ $t("audience.updateDate") }}
              </th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                {{ $t("audience.actions") }}
              </th>
            </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
            <tr v-for="(segmentIntegration, index) in segmentIntegrations" :key="index">
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <span class="inline-flex rounded-full bg-green-100 text-green-800 px-2 text-xs font-semibold leading-5"
                      :class="segmentIntegration.is_active == true ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'">{{ segmentIntegration.is_active == 1 ? $t("audience.active") : $t("audience.inactive")
                  }}</span>
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {{ segmentIntegration.config_schedule == null ? $t("audience.simple") : $t("audience.dynamic") }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {{ $moment(segmentIntegration.created_at).format("DD-MM-YYYY, hh:mm") || $t("audience.notData") }}
              </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {{ $moment(segmentIntegration.sync_at).format("DD-MM-YYYY, hh:mm") || $t("audience.notData") }}
              </td>
              <td class="flex-center space-x-2 justify-end relative whitespace-nowrap p-4 text-sm font-medium">
                <Button v-if="$verifyPermission(permissions, ['v2.client.segment.integration.update'])" @click="editSegmentSync(segmentIntegration)" :label="$t('audience.edit')" icon="pi pi-pencil"
                        iconPos="left" class="p-button-sm p-button-secondary shadow" />
                <Button v-if="segmentIntegration.is_active && $verifyPermission(permissions, ['v2.client.segment.integration.change.active'])" @click="pauseSegmentSync(segmentIntegration, index)"
                        :label="segmentIntegration.is_active ? $t('audience.pause') : $t('audience.reactive')"
                        icon="pi pi-pause" iconPos="left" class="p-button-light shadow p-button-sm" />
                <Button v-else-if="$verifyPermission(permissions, ['v2.client.segment.integration.change.active'])" @click="pauseSegmentSync(segmentIntegration, index)" :label="segmentIntegration.is_active ? $t('audience.pause') : $t('audience.reactive')" icon="pi pi-play" iconPos="left" class="p-button-light shadow p-button-sm" />
                <Button v-if="$verifyPermission(permissions, ['v2.client.segment.integration.sync'])" @click="syncNow(segmentIntegration.id)" :label="$t('audience.syncNow')" icon="pi pi-sync" iconPos="left" class="p-button-info p-button-sm shadow" />
                <Button v-if="$verifyPermission(permissions, ['v2.client.segment.integration.delete'])" @click="deleteSegmentSync(segmentIntegration.id, index)" :label="$t('audience.delete')" icon="pi pi-trash" iconPos="left" class="p-button-danger p-button-sm shadow" />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <Dialog :header="$t('audience.syncro')" v-model:visible="model.segmentIntegration.show"
          :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '70vw' }" :modal="true">
    <FormSegmentIntegration :segment="dataSegment" :segmentIntegration="segmentIntegration"
                            @finish="finishSegmentIntegration()"></FormSegmentIntegration>
  </Dialog>
</template>
<style>
.swal2-container {
  z-index: 20000 !important;
}
</style>
<script>
import Button from "primevue/button"
import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"
import FormSegmentIntegration from "./FormSegmentIntegration.vue"
import Dialog from "primevue/dialog"
import axiosClient from "@/config/AxiosClient"
import { mapGetters } from 'vuex'

const sprintf = require("sprintf-js").sprintf

export default {
  name: "SegmentIntegrationList",
  components: {
    Button,
    FormSegmentIntegration,
    Dialog
  },

  props: {
    segment: Object,
    channel: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  computed: {
    ...mapGetters({permissions: 'Projects/permissionsForProject'}),
    dataSegment() {
      if (this.segment.segmentId) return { id: this.segment.segmentId }
      else return this.segment
    }
  },

  data() {
    return {
      segmentIntegrations: [],
      model: {
        segmentIntegration: {
          show: false
        }
      }
    }
  },

  created() {
    this.segmentIntegrations = this.segment.segment_integrations.filter((f) => f.project_credential.channel_id == this.channel.id)
  },

  methods: {
    async syncNow(segmentIntegrationId) {
      await new FormClient().get(sprintf(ApiRoute.segment.syncNow, { segment_integration_id: segmentIntegrationId }))
      this.$toast.success(this.$t("audience.syncSuccess"))
    },

    async pauseSegmentSync(segmentIntegration, index) {
      await new FormClient().get(sprintf(ApiRoute.segment.changeActive, { segment_integration_id: segmentIntegration.id }))
      this.segmentIntegrations[index].is_active = !this.segmentIntegrations[index].is_active
      this.$toast.success(segmentIntegration.is_active ? this.$t("audience.reactiveSuccess") : this.$t("audience.pauseSuccess"))
      this.$emit("refreshSegments")
    },

    async editSegmentSync(sync) {
      this.segmentIntegration = sync
      this.model.segmentIntegration.show = true
    },

    async deleteSegmentSync(segmentIntegrationId) {
      this.$swal({
        title: this.$t("utils.alerts.sure"),
        text: this.$t("utils.alerts.deleteText"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("utils.alerts.confirmButton"),
        cancelButtonText: this.$t("utils.alerts.cancelButton")
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await axiosClient.delete(`${process.env.VUE_APP_URL_API}/api/2.0/segment/integration/${segmentIntegrationId}`)
          if (res.status == 204) {
            this.$emit("refreshSegments")
            this.$toast.success(this.$t("utils.toast.syncDeleted"))
            await this.getSegmentIntegrations()

          }

        }
      })

    },

    async getSegmentIntegrations() {
      let response = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/segment/${this.segment.id}/integration?include=project_credential.channel&filter[project_credential.channel_id][=]=${this.channel.id}&filter[id][=]=${this.channel.id}`)
      this.segmentIntegrations = response.data.data
    },

    async finishSegmentIntegration() {
      this.$emit("refreshSegments")
      await this.getSegmentIntegrations()
      this.model.segmentIntegration.show = false
    }
  }
}
</script>
