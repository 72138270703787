<template>
    <div class="bg-white w-11/12 rounded-md" v-if="isActiveItemInfo" >
        
        <div class="h-16 w-full flex items-center justify-between rounded-t-md px-4 border-b border-gray-200">
            <div>
                <h4 class="text-gray-500 font-semibold">{{itemInfo.name}}</h4>
                <div class="flex flex-row items-center justify-start">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mr-3  h-5 w-5 text-gray-400">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <small class="text-gray-400 ">{{itemInfo.timestamp}}</small>
                </div>
            </div>
            <button @click="close" class="text-red-500 hover:bg-red-100 hover:shadow transition-all ease-out duration-500 p-1 rounded ">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </button>
        </div>
        <div class="modalHeight w-full p-6 flex flex-col md:flex-col lg:flex-row xl:flex-row justify-center md:items-center">
            <div class="w-full md:w-2/3 lg:w-2/5 xl:w-2/5 my-2 md:my-2 lg:my-0 xl:my-0 p-16 flex items-center justify-center">
                <img :src="itemInfo.image ? itemInfo.image : 'https://www.uliko.com.py/images/image-not-found.png'" :alt="itemInfo.name" class="w-4/5">
            </div>
            <div class="w-full max-h-full max-w-full md:w-3/5 xl:w-3/5 my-2 md:my-2 lg:my-0 xl:my-0 overflow-y-auto">
            
                <div class="w-full lg:w-full xl:w-3/6 py-4 flex row md:justify-center lg:justify-end lg:pr-5 xl:justify-end xl:items-center">
                    <div class="w-full mt-5 mb-2 border-2 border-blue-200 focus-within:border-blue-400 py-1 px-3 flex justify-between rounded-md md:w-60 shadow-sm">
                        <input
                            v-model="search"
                            type="text" :placeholder="t('content.search',{},{locale: $store.state.Langs.selected.lang})"
                            class="flex-grow outline-none text-gray-400 focus:text-gray-800"
                        />
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-blue-300 hover:text-blue-500 transition duration-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </span>
                    </div>
                </div>

                <div class="flex flex-col md:flex-row items-center justify-between my-2" v-for="(item, i) in filteredItems" :key="i">
                    <p class="text-sm text-gray-400 text-start md:text-right font-semibold w-full md:w-1/4">{{item}}</p>
                    <p class="md:ml-6 text-sm md:text-md text-center md:text-left  rounded-lg w-full md:w-3/4 overflow-ellipsis overflow-hidden" v-if="typeof itemInfo[item] == 'string'">{{ itemInfo[item] }}</p>
                    <p class="md:ml-6 text-sm md:text-md text-center md:text-left  rounded-lg w-full md:w-3/4" v-if="typeof itemInfo[item] == 'number'">{{ itemInfo[item] }}</p>
                    <json-viewer
                        :value="itemInfo[item]"
                        v-if="typeof itemInfo[item] == 'object'"
                        preview
                        class="md:ml-6 text-sm md:text-md  text-gray-500 md:py-2 md:px-4 w-3/4"
                        >
                    {{ itemInfo[item] }}
                    </json-viewer>
                </div>
            </div>
        </div>
        <div class="h-16 w-full flex items-center justify-end rounded-t-md px-4 border-t border-gray-200">
            <button @click="close" class="btn btn-neutral btn-xs">{{ t("content.close",{},{locale: $store.state.Langs.selected.lang}) }}</button>
        </div>
    </div>
</template>

<script> 
    import{useI18n} from 'vue-i18n'
    export default {
        props:['itemInfo', 'isActiveItemInfo'],
        emits:['ModalClose'],
        setup(){
          const { t, locale } = useI18n();
          return { t , locale }
        },
        data(){
            return {
                search: ''
            }
        },
        computed: {
            filteredItems() {
                if (!this.search) {
                    return Object.keys(this.itemInfo)
                }

                return Object
                    .entries(this.itemInfo)
                    .filter(
                        (item) => item[0].toLowerCase().includes(
                            this.search.toLowerCase()) || JSON.stringify(item[1]).toLowerCase().includes(this.search.toLowerCase()
                        )
                    )
                    .map(item => item[0])
            }
        },
        methods:{
            close:function(){
                this.$emit('ModalClose')
            },
        }
    }

</script>
