<template>
  <div class="w-full bg-gray-100 py-3 px-5 flex flex-col items-start rounded shadow">
    <div class="flex justify-start items-center mb-5">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="w-5 h-5 mr-3">
        <path d="M128 64c0-17.7 14.3-32 32-32H320c17.7 0 32 14.3 32 32V416h96V256c0-17.7 14.3-32 32-32H608c17.7 0 32 14.3 32 32s-14.3 32-32 32H512V448c0 17.7-14.3 32-32 32H320c-17.7 0-32-14.3-32-32V96H192V256c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h96V64z" />
      </svg>

      <p class="text-gray-600 text-sm font-semibold">{{ $t('audience.frequency')}}</p>
    </div>
    <div class="w-full">
      <div class="w-full flex justify-between items-center">
        <div @click="switchSyncToSimple" class="transition-all w-2/4 border-transparen px-3 py-2 text-sm font-medium shadow-sm flex justify-center items-center border border-gray-500 rounded p-3 cursor-pointer" :class="isSimpleSync ? 'bg-white' : 'bg-gray-300 opacity-60 hover:opacity-100'">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12 mr-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
          </svg>
          <div class="flex flex-col justify-center items-start">
            <p class="font-semibold">{{ $t('audience.simpleSync')}}</p>
            <small>{{ $t('audience.simpleInfo')}}</small>
          </div>
        </div>

        <div @click="switchSyncToDinamic" class="transition-all ml-2 w-2/4 border-transparen px-3 py-2 text-sm font-medium shadow-sm flex justify-center items-center border border-gray-500 rounded p-3 cursor-pointer" :class="isSimpleSync ? 'bg-gray-300 opacity-60 hover:opacity-100' : 'bg-white'">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12 mr-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" />
          </svg>
          <div class="flex flex-col justify-center items-start">
            <p class="font-semibold">{{ $t('audience.dynSync')}}</p>
            <small>{{ $t('audience.dynInfo')}}</small>
          </div>
        </div>
      </div>

      <div class="border border-dashed border-gray-500 rounded mt-3 p-3 transition-all" :class="isSimpleSync ? 'cursor-not-allowed bg-gray-300 bg-opacity-50 opacity-30' : 'cursor-pointer'">
        <div class="flex flex-col justify-center items-start">
          <p class="text-gray-600 text-sm font-normal">{{ $t('audience.days')}}</p>
          <span class="w-full flex justify-start items-center my-2">
            <div class="mx-2 flex items-center" v-for="day in week" :key="day">
              <Checkbox :binary="true" v-model="day.isActive" :value="day.value" :name="day.name" :disabled="isSimpleSync" />
              <label :for="day.name" class="ml-1 text-gray-600 text-sm font-normal">{{ day.name }}</label>
            </div>
          </span>

          <span class="w-full flex items-center">
            <p class="text-sm">{{ $t('audience.scheduleDay')}}</p>
            <div class="w-52 mx-3 cursor-pointer">
              <Multiselect v-model="hours" mode="tags" :close-on-select="false" :searchable="true" :options="timetables" class="multiselect-blue" label="label" trackBy="label" valueProp="value" :disabled="isSimpleSync" :max="2" />
            </div>
            <p class="italic text-gray-400 text-sm drop-shadow-lg">{{ $t('audience.infoSync')}}</p>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect"
import Checkbox from "primevue/checkbox"

export default {
  emits: ["frequencyData"],
  props: {
    selectFrequency: Object
  },
  name: "Frequency",
  components: {
    Multiselect,
    Checkbox
  },
  data() {
    return {
      dataSelected: null,
      isSimpleSync: true,
      week: [
        { isActive: true, value: 0, name: this.$t('audience.sunday') },
        { isActive: true, value: 1, name: this.$t('audience.monday') },
        { isActive: true, value: 2, name: this.$t('audience.tuesday') },
        { isActive: true, value: 3, name: this.$t('audience.wednesday') },
        { isActive: true, value: 4, name:  this.$t('audience.thursday') },
        { isActive: true, value: 5, name:  this.$t('audience.friday') },
        { isActive: true, value: 6, name:  this.$t('audience.saturday') }
      ],
      timetables: [
        { value: "00:00", label: "00:00" },
        { value: "01:00", label: "01:00" },
        { value: "02:00", label: "02:00" },
        { value: "03:00", label: "03:00" },
        { value: "04:00", label: "04:00" },
        { value: "05:00", label: "05:00" },
        { value: "06:00", label: "06:00" },
        { value: "07:00", label: "07:00" },
        { value: "08:00", label: "08:00" },
        { value: "09:00", label: "09:00" },
        { value: "10:00", label: "10:00" },
        { value: "11:00", label: "11:00" },
        { value: "12:00", label: "12:00" },
        { value: "13:00", label: "13:00" },
        { value: "14:00", label: "14:00" },
        { value: "15:00", label: "15:00" },
        { value: "16:00", label: "16:00" },
        { value: "17:00", label: "17:00" },
        { value: "18:00", label: "18:00" },
        { value: "19:00", label: "19:00" },
        { value: "20:00", label: "20:00" },
        { value: "21:00", label: "21:00" },
        { value: "22:00", label: "22:00" },
        { value: "23:00", label: "23:00" }
      ],
      hours: []
    }
  },
  async mounted() {
    if(this.selectFrequency != null) {
      if (Object.keys(this.selectFrequency).length) {
        this.dataSelected = this.selectFrequency

        // Get type
        if (this.dataSelected != null) {
          this.isSimpleSync = false

          this.week.forEach((option) => {
            // Set the checkbox day to FALSE
            if(!this.dataSelected.days.includes(option.value)) {
              option.isActive = false
            }
          })

          this.hours = this.dataSelected.dailyAt
        } else {
          this.isSimpleSync = true
        }
      }
    }
    
  },
  methods: {
    switchSyncToSimple() {
      this.isSimpleSync = true
    },
    switchSyncToDinamic() {
      this.isSimpleSync = false
    },
    saveFrequencySettings() {
      let frequencyData = {}
      if (this.isSimpleSync) {
        frequencyData.days = null
        frequencyData.hours = null
        frequencyData.isSimple = true
      } else {
        frequencyData.days = this.week.filter((f) => f.isActive != false)
        frequencyData.hours = this.hours
        frequencyData.isSimple = false
      }
      this.$emit("frequencyData", { hours: frequencyData.hours, week: frequencyData.days, isSimpleMode: frequencyData.isSimple })
    }
  }
}
</script>
<style scoped>
.multiselect-blue {
  --ms-tag-bg: #4b5563;
  --ms-tag-color: #e3e4e7;
}
</style>
