<template>
  <ul class="grid grid-cols-12 gap-4 w-full">
    <li
      class="relative flex-1 col-span-6 sm:col-span-6 md:col-span-4 lg:col-span-4 xl:col-span-2 bg-white rounded-md border"
      v-for="(article, i) in 100"
      :key="i"
    >
      <div class="bg-white  border-gray-200 p-2 space-y-2 w-full h-auto rounded-t-md">
        <div class="h-56 w-full">
          <div
            class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse"
            loading="lazy"
          />
        </div>
      </div>
      <div class="p-2 w-full">
        <p class="h-6 bg-gray-200 w-full animate-pulse"></p>
      </div>
      <div class="p-2 w-full">
        <p class="h-6 bg-gray-200 animate-pulse"></p>
      </div>
      <div class="p-2 w-full">
        <p class="h-10 bg-gray-200 animate-pulse"></p>
      </div>
    </li>
  </ul>
</template>

<script>
export default {};
</script>

