<template>
  <div>
    <Card>
      <template #header>
        <img class="bg-cover w-full cursor-pointer pt-2" loading="lazy" :src="[article.image !== null ? article.image : 'https://www.uliko.com.py/images/image-not-found.png']" :alt="article.name" style="backgroundImage" :title="article.name" @click="showItemInfo(article)" />
      </template>
      <template #content>
        <div class="flex flex-col justify-center items-center -m-2">
          <p class="text-center text-xs font-semibold text-gray-800 cursor-pointer" :title="article.name">
            {{ article.name }}
          </p>
          <div class="flex flex-row items-center justify-center space-x-2 h-10">
            <p :class="[article.price_sale > 0 ? 'font-normal text-gray-400 text-overline' : 'font-semibold text-gray-800 ']">
              {{ $filters.currency(article.price ? article.price : 0) }}
            </p>
            <p class="font-semibold text-gray-800" v-if="article.price_sale > 0">
              {{ $filters.currency(article.price_sale ? article.price_sale : 0) }}
            </p>
            <small class="bg-indigo-500 text-white px-1 rounded" v-if="calculateDiscount(article.price, article.price_sale) > 0">{{ calculateDiscount(article.price, article.price_sale) }}%</small>
          </div>
          <div>
            <Button type="button" icon="pi pi-eye" class="p-button-sm" :class="[article.visible == '1' ? 'p-button-success' : 'p-button-secondary']" v-tooltip.top="article.visible == '1' ? 'Disponible' : 'No disponible'" />
            <Button type="button" icon="pi pi-calendar-minus" class="mx-1 p-button-sm p-button-secondary" v-tooltip.top="`Modificado: ${$moment(article.timestamp).fromNow(true)}`" />
            <Button @click="externalSite(article.link)" type="button" icon="pi pi-window-maximize" class="p-button-sm p-button-warning" v-tooltip.top="'Ver en la tienda'" />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="-m-4">
          <div class="text-xs font-medium text-gray-800 mb-1">Categorias</div>
          <ul class="flex-center gap-1 flex-wrap" v-if="article.categories.length > 0">
            <div v-for="category in article.categories" :key="category.category_id">
              <small class="inline-flex items-center rounded-full bg-gray-200 px-3 py-0.5 text-[0.6rem] font-light text-gray-800 truncate">
                {{ category.category_name }}
              </small>
            </div>
          </ul>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card"
import Button from "primevue/button"
export default {
  props: ["article"],
  emits: ["ModalOpen"],
  components: {
    Card,
    Button
  },
  methods: {
    showItemInfo: function (value) {
      this.$emit("ModalOpen", value)
    },

    calculateDiscount: function (p, p_s) {
      let discount = 0
      const price = parseFloat(p)
      const price_sale = parseFloat(p_s)
      if (price > price_sale && price_sale > 0) {
        discount = Math.round(100 - (price_sale * 100) / price)
      }
      return discount
    },

    externalSite: function (link) {
      window.open(link, "_blank")
    }
  }
}
</script>
