<template>
  <div class="px-0 py-2 mx-auto w-full my-4 flex flex-col align-middle justify-center items-center rounded">
    <div class="w-full border border-gray-200 rounded-lg">
      <table class="min-w-full rounded-lg">
        <thead class="bg-white">
          <tr>
            <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-tl-lg">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-2 py-2 inline-block" loading="lazy"></div>
            </th>
            <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-2 py-2 inline-block" loading="lazy"></div>
            </th>
            <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-2 py-2 inline-block" loading="lazy"></div>
            </th>
            <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-2 py-2 inline-block" loading="lazy"></div>
            </th>
            <th scope="col" class="px-4 py-4 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider rounded-tr-lg">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-2 py-2 inline-block" loading="lazy"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-white hover:bg-gray-100 transition-all ease-out duration-500 cursor-default border border-gray-100">
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
          </tr>
          <tr class="bg-white hover:bg-gray-100 transition-all ease-out duration-500 cursor-default border border-gray-100">
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
          </tr>
          <tr class="bg-white hover:bg-gray-100 transition-all ease-out duration-500 cursor-default border border-gray-100">
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
          </tr>
          <tr class="bg-white hover:bg-gray-100 transition-all ease-out duration-500 cursor-default border border-gray-100">
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
          </tr>
          <tr class="bg-white hover:bg-gray-100 transition-all ease-out duration-500 cursor-default border border-gray-100">
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
          </tr>
          <tr class="bg-white hover:bg-gray-100 transition-all ease-out duration-500 cursor-default border border-gray-100">
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
          </tr>
          <tr class="bg-white hover:bg-gray-100 transition-all ease-out duration-500 cursor-default border border-gray-100">
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500 rounded-bl-lg">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
            <td class="px-4 text-left py-4 whitespace-nowrap text-sm text-gray-500 rounded-br-lg">
              <div class="bg-cover h-full bg-center bg-gray-200 w-full animate-pulse px-4 py-4 inline-block" loading="lazy"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {}
</script>
