<template>
  <div>
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" class="h-14 w-14 md:h-20 md:w-20 text-gray-500 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
      </svg>
    </div>
    <div class="space-y-4 flex flex-col items-center justify-center">
        <slot></slot>
        <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoResultsFound',
};
</script>


