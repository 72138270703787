<template>
  <div class="w-full bg-gray-100 py-3 px-5 flex flex-col items-start rounded shadow">
    <div class="flex justify-start items-center mb-5">
      <Icon icon="tabler:users" class="w-6 h-6 mr-5 text-gray-400" />
      <p class="text-gray-600 text-sm font-semibold">{{ $t("audience.profiles") }}</p>
    </div>
    <div class="w-full">
      <div class="w-full flex justify-between items-center">
        <ul class="w-full">
          <li v-for="(input, i) in inputsArray" :key="i">
            <div>
              <div class="form-group">
                <div class="flex-center justify-between">
                  <div class="text-gray-600 text-sm font-normal">{{ $t(`wizard.inputsCustom.${input.name}`) }} <LabelRequired v-if="input.required" /></div>
                  <div class="ml-auto w-52">
                    <InputSwitch v-if="['checkbox'].includes(input.type)" v-model="input.value" class="float-right" />
                    <InputNumber v-if="['number'].includes(input.type)" v-model="input.value" class="p-inputtext-sm shadow-sm" />
                    <InputText v-if="['text'].includes(input.type)" v-model="input.value" class="p-inputtext-sm shadow-sm" />
                    <Dropdown v-if="['select', 'multiselect'].includes(input.type)" v-model="input.value" :options="input.options" :optionLabel="input.label" :optionValue="input.keyValue" :dataKey="input.keyValue" :placeholder="$t('select')" class="p-inputtext-sm shadow-sm w-full" />
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li v-if="showAutomapper" class="flex justify-between items-center mb-2">
            <div class="inline-flex">
              <small class="text-gray-600 text-sm font-normale">{{ $t("integrations.automapper.relateFields") }}</small>
            </div>
            <Button @click="showModalRelate()" type="button" class="p-button-sm p-button-light flex-center space-x-2 ml-auto">
              <Icon icon="tabler:columns" class="w-6 h-6" />
              <span class="font-semibold">{{ $t("integrations.automapper.buttonConfig") }}</span>
            </Button>
          </li>
        </ul>
      </div>
      <div class="w-full flex justify-between items-center mt-2">
        <p class="text-gray-600 text-sm font-normal">{{ $t("priority.title") }}</p>
        <div class="ml-auto w-52">
          <Dropdown v-model="priority" :options="prioritys" optionLabel="label" optionValue="value" dataKey="value" :placeholder="$t('select')" class="p-inputtext-sm shadow-sm w-full" />
        </div>
      </div>
    </div>

    <Dialog :header="'&nbsp;'" v-model:visible="isModalRelateOpen" modal :dismissableMask="true" :style="{ width: '60rem', padding: 0 }">
      <div class="form-group">
        <table class="table-auto">
          <thead>
            <tr>
              <td width="31%">
                <div class="flex-center space-x-2">
                  <img src="https://setup.baymark.net/storage/channels/images/channel_7_short.png" class="w-14 h-14 object-contain" v-tooltip.top="'SetUp'" />
                  <div class="flex-1">
                    <span class="form-label-inline">{{ $t("integrations.automapper.fieldsFrom") }}</span>
                    <span class="form-label-inline">({{ $t("required") }})</span>
                  </div>
                </div>
              </td>
              <td width="4%"></td>
              <td width="31%">
                <div class="flex-center space-x-2">
                  <img v-if="channel" :src="channel.avatar_short" class="w-14 h-14 object-contain" v-tooltip.top="channel.name" />
                  <div class="flex-1">
                    <span class="form-label-inline">{{ $t("integrations.automapper.fieldsTo") }}</span>
                    <span class="form-label-inline">({{ $t("required") }})</span>
                  </div>
                </div>
              </td>
              <td width="31%">
                <span class="form-label-inline">{{ $t("integrations.automapper.transformingData") }}</span>
                <span class="form-label-inline">({{ $t("optional") }})</span>
              </td>
              <td width="3%"></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(field, index) in relateInputs" :key="index">
              <td>
                <!-- From field -->
                <div class="mr-3">
                  <Dropdown v-model="field.field_from" :options="$store.state.Audience.projectAudienceKeys" optionLabel="label" optionValue="key" dataKey="key" :filter="true" :autoFilterFocus="true" :placeholder="$t('select')" class="p-inputtext-sm shadow-sm w-full p-dropdown-automapper" />
                </div>
              </td>
              <td>
                <!-- Icon -->
                <div class="mr-3">
                  <Icon icon="tabler:arrow-big-right-lines" class="w-6 h-6" />
                </div>
              </td>
              <td>
                <!-- To field -->
                <div class="mr-3">
                  <Dropdown v-model="field.field_to" :options="fieldsOptions" optionLabel="label" optionValue="key" dataKey="key" :filter="true" :autoFilterFocus="true" :editable="service.config?.editableFields" :placeholder="$t('select')" class="p-inputtext-sm shadow-sm w-full p-dropdown-automapper" />
                </div>
              </td>
              <td>
                <!-- Function field -->
                <div class="mr-3">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <Icon icon="carbon:chemistry" class="w-4 h-4" />
                    </span>
                    <InputText v-model="field.function" name="function" class="p-inputtext-sm shadow-sm" />
                  </div>
                </div>
              </td>
              <td>
                <!-- Remove field -->
                <Button @click="relateInputs.splice(index, 1)" type="button" class="p-button-sm p-button-danger" v-tooltip.top="$t('integrations.automapper.removeField')">
                  <Icon icon="tabler:trash" class="w-6 h-6" />
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="w-full flex flex-col justify-center items-center mt-5">
        <div class="w-full inline-flex justify-start">
          <Button type="button" @click="relateInputs.push({ field_from: null, field_to: null, function: null })" class="p-button-light flex-center space-x-2">
            <Icon icon="tabler:circle-plus" class="w-6 h-6 text-gray-700" />
            <span>{{ $t("integrations.automapper.addField") }}</span>
          </Button>
        </div>
      </div>
      <template #footer>
        <Button type="button" @click="showModalRelate" :label="$t('integrations.automapper.cancelButton')" class="p-button-secondary p-button-text" />
        <Button type="button" @click="saveRelateFields" :label="$t('integrations.automapper.saveButton')" class="p-button-primary shadow-md" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog"
import LabelRequired from "@/components/LabelRequired"
import Dropdown from "primevue/dropdown"
import InputSwitch from "primevue/inputswitch"
import InputText from "primevue/inputtext"
import InputNumber from "primevue/inputnumber"
import { Icon } from "@iconify/vue"
import Button from "primevue/button"
import axiosClient from "@/config/AxiosClient"

export default {
  name: "ServiceSegment",
  emits: ["dataService"],
  components: {
    Dialog,

    LabelRequired,
    InputNumber,
    InputText,
    InputSwitch,
    Dropdown,
    Button,
    Icon
  },
  props: {
    showAutomapper: Boolean,
    inputsArray: Array,
    fieldsOptions: Array,
    selectConfig: Object,
    channel: Object,
  },
  computed: {
    service() {
      return this.channel.services_segment[0]
    }
  },
  data() {
    return {
      dataSelected: null,

      isModalConfigOpen: false,
      isModalRelateOpen: false,

      relateInputs: [],
      modalConfigJSON: "",
      automapper: [],
      optionsFieldFrom: [],
      priority: 0,
      prioritys: []
    }
  },
  async mounted() {
    this.prioritys = [
      {label: this.$t('priority.critical'), value: 1},
      {label: this.$t('priority.high'), value: 2},
      {label: this.$t('priority.medium'), value: 3},
      {label: this.$t('priority.low'), value: 0}
    ]
    this.optionsFieldFrom = await axiosClient.get(`api/2.0/segment/fields/${this.$route.params.id}`)
    if (Object.keys(this.selectConfig).length) {
      this.priority = this.selectConfig.priority
      this.dataSelected = this.selectConfig

      this.inputArray = this.inputsArray.map((input) => {
        if (this.dataSelected.config != null && this.dataSelected.config[input.name]) {
          input.value = this.dataSelected.config[input.name]
        }
        return input
      })


      if (this.dataSelected?.automapper && this.dataSelected?.automapper.length >= 1){
        this.relateInputs = this.dataSelected.automapper
      }else{
        this.relateInputs.push({ field_from: 'email', field_to: this.fieldsOptions.find(f => f.label == 'Email') ? 'Email' : 'email', isActive: false, function: null })
        this.automapper.push({field_from: 'email', field_to: this.fieldsOptions.find(f => f.label == 'Email') ? 'Email' : 'email'})
      }
    }else{
      this.relateInputs.push({ field_from: 'email', field_to: this.fieldsOptions.find(f => f.label == 'Email') ? 'Email' : 'email', isActive: false, function: null })
      this.automapper.push({field_from: 'email', field_to: this.fieldsOptions.find(f => f.label == 'Email') ? 'Email' : 'email'})
    }
  },
  methods: {
    showModalRelate() {
      this.isModalRelateOpen = !this.isModalRelateOpen
    },
    saveRelateFields() {
      let automapper = JSON.parse(
        JSON.stringify(
          this.relateInputs.map(function (a) {
            let itemReturn = { field_from: a.field_from, field_to: a.field_to }
            if (a.function != null && a.function != "") {
              itemReturn.function = a.function
            }
            return itemReturn
          })
        )
      )
      this.automapper = automapper
      this.isModalRelateOpen = false
      this.relateInputs = [{ field_from: null, field_to: null, function: null, isActive: false }]
      this.$toast.info("Campos relacionados correctamente")
    },
    activeDesactiveFunction(indexAutompper) {
      this.relateInputs[indexAutompper].isActive = !this.relateInputs[indexAutompper].isActive
      if (!this.relateInputs[indexAutompper].isActive) this.relateInputs[indexAutompper].function = null
    },
    verifyServiceData() {
      let data = {}
      data.config = this.inputsArray.reduce((acc, val) => {
        let valReturn = { ...acc }
        valReturn[val.name] = val.value
        return valReturn
      }, {})
      data.automapper = this.automapper

      this.$emit("dataService", { config: data.config, automapper: data.automapper, priority: this.priority })
      this.resetData(data)
    },
    resetData(data) {
      data.config = null
      data.automapper = null
      this.modalConfigJSON = ""
      this.relateInputs = [{ field_from: null, field_to: null, function: null, isActive: false }]
    }
  }
}
</script>

<style scoped>
.multi,
.multiselect {
  width: 40% !important;
  margin: 0 0 !important;
}
</style>
