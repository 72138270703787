<template>
  <div>
    <!--Query-Builder Modal-->
    <vue-final-modal v-model="$store.state.Menu.isModalQueryFilterOpen" @keydown.esc="ModalClose" tabindex="0">
      <div class="h-screen">
        <div class="flex flex-col h-full items-center justify-center">
          <div class="bg-white rounded-md w-screen sm:w-2/5 2xl:w-3/5">
            <div class="h-16 w-full flex items-center justify-between rounded-t-md px-4 border-b border-gray-200">
              <h4 class="text-gray-500 font-semibold py-3">{{ $t("content.advanced") }}</h4>
              <Button @click="ModalClose" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" />
            </div>
            <div class="w-full overflow-y-auto p-6" :class="{'modalHeight': !errorQueryBuilder, 'h-28': errorQueryBuilder}">
              <div class="alert alert-error p-2.5 mt-1 mb-2" v-if="errorQueryBuilder">
                <div class="flex-1">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        class="w-7 h-7 mr-2 stroke-current">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                  </svg>
                  <label class="text-lg">{{ $t('content.errorQueryBuilder') }}</label>
                </div>
              </div>
              <div class="card-dashed" v-if="!errorQueryBuilder">
                <h4 class="card-dashed-title">{{ $t("content.attributes") }}</h4>
                <div class="card-dashed-body">
                  <div id="builder"></div>
                </div>
              </div>
            </div>
            <div class="h-16 w-full flex items-center justify-end rounded-t-md px-4 border-t border-gray-200" v-if="!errorQueryBuilder">
              <Button @click="getContentFiltered" :label="$t('content.saveFilter')" class="p-button-success" />
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
    <!--TopNav shows only when is creating basic segment-->
    <div class="h-16 px-4" v-if="$store.state.Menu.isTopNavModalCollection">
      <div class="h-full w-full px-2 mx-auto container flex flex-row items-center justify-end gap-2">
        <button v-show="(segment == 'new' && $store.state.Audience.typeSelected == 'custom') || $store.state.Audience.typeSelected == 'library' || $store.state.Audience.typeSelected == 'basic'" @click="TypeSelected('basic')" class="btn btn-xs btn-neutral-inverse">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="w-5 h-5 mx-2 stroke-current">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
          </svg>
          {{ $t("content.return") }}
        </button>
        <button class="btn btn-xs btn-warning" @click="selectCollectionBasicSegment">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
          </svg>
          <span>{{ $t("content.select") }}</span>
        </button>
      </div>
    </div>
    <!--SubNav Subtitle and buttons-->
    <div class="border-t bg-white">
      <div class="container-xl px-4 py-4 md:px-2 h-20 md:h-20 mx-auto flex flex-row items-center justify-between">
        <!--Left Filter-->
        <div class="flex flex-row items-center justify-between lg:justify-start space-x-1">
          <h4 class="text-xl font-semibold leading-tight text-gray-800 flex items-center mr-0 md:mr-5">
            {{ t("content.content", {}, { locale: $store.state.Langs.selected.lang }) }}
          </h4>
          <div class="relative" v-click-away="closeMenuCollection" @keydown.esc="closeMenuCollection">
            <button :disabled="!collection.length" class="btn btn-select btn-xs no-animation" @click="isMenuCollectionOpen = !isMenuCollectionOpen">
              <span>
                {{ collectionSelectedId }}
                <span v-if="collectionSelectedId">-</span>
                {{ collectionSelectedName }}
              </span>
              <svg v-if="collection.length" xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down w-5 h-5 ml-4" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#758599" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="6 9 12 15 18 9" />
              </svg>
            </button>
            <!-- Dropdown menu -->
            <TransitionFade>
              <ul class="absolute overflow-y-auto left-0 mt-2 bg-white dark:bg-gray-800 rounded-lg shadow-xl z-20 border divide-y divide-gray-100" :class="[collection && collection.length > 7 ? 'h-96' : 'h-auto']" v-if="isMenuCollectionOpen">
                <li class="h-14 w-72 text-sm flex flex-col items-start justify-center cursor-pointer hover:bg-gray-50 transition ease-out duration-300" v-for="c in collection" :key="c.id" @click="selectCollection(c)">
                  <span class="font-semibold text-gray-500 inline-block px-2">{{ c.id }} - {{ c.name }}</span>
                </li>
              </ul>
            </TransitionFade>
          </div>
        </div>
        <!--Rigth Filter-->
        <div class="flex flex-row items-center space-x-2">
          <Button @click="resetFilter" icon="pi pi-pencil" :label="$t('content.reset')" class="p-button-secondary" />
          <Button v-if="$verifyPermission(permissions, ['v1.collection.store'])" @click="OpenModal" icon="pi pi-search" :label="$t('content.advanced')" class="p-button-success" />
          <div class="relative inline-block text-left">
            <div>
              <button @click.stop="showCopyDropdown" type="button" class="btn btn-select btn-xs p-button-outlined p-button-secondary" id="menu-button" aria-expanded="true" aria-haspopup="true">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="w-5 h-5 mr-1" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                </svg>
                <p class="hidden sm:hidden md:block lg:block xl:block">{{ t("content.copy", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
            <!-- dropdown -->
            <div v-if="copyDropdown" v-click-away="closeCopyDropdown" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-40" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
              <div class="py-1" role="none">
                <a @click="copyType(1)" href="javascript:void(0)" class="text-gray-400 hover:text-gray-600 cursor-pointer block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">
                  {{ t("content.copyXML", {}, { locale: $store.state.Langs.selected.lang }) }}
                </a>
                <a @click="copyType(2)" href="javascript:void(0)" class="text-gray-400 hover:text-gray-600 cursor-pointer block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">
                  {{ t("content.copyJSON", {}, { locale: $store.state.Langs.selected.lang }) }}
                </a>
                <a @click="copyType(3)" v-if="collectionSelectedName != 'Collections'" href="javascript:void(0)" class="text-gray-400 hover:text-gray-600 cursor-pointer block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">
                  {{ t("content.copyCollection", {}, { locale: $store.state.Langs.selected.lang }) }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--SubNav Filter-->
    <div class="border-t bg-white border-b border-gray-200">
      <div class="container-xl w-full px-2 mx-auto h-14 flex flex-row lg:flex-row md:items-center lg:items-center justify-between">
        <!--Search-->
        <div class="h-full w-full flex flex-row items-center">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5 text-gray-400 mr-2">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
          <input type="text" :placeholder="t('content.search', {}, { locale: $store.state.Langs.selected.lang })" class="h-full w-full px-2 focus:outline-none focus:border-none" v-model="filterKeyword" v-debounce:700ms="search" />
        </div>
        <div class="flex flex-row items-center relative" v-if="this.$route.name == 'Content'">
          <div class="relative" v-if="storeCollectionBtn">
            <Button @click="isSaveCollectionFormOpen = !isSaveCollectionFormOpen" icon="pi pi-save" :label="$t('content.saveCollection')" class="p-button-primary shadow-sm" />
            <!-- Dropdown menu -->
            <TransitionFade>
              <div class="absolute right-0 mt-2 w-[35rem] bg-white dark:bg-gray-800 rounded-md shadow-xl z-20 border px-2 py-4 flex justify-between gap-2" v-if="isSaveCollectionFormOpen">
                <InputText v-model="collectionName" type="text" class="p-inputtext-sm" placeholder="Nombre de coleccion." />
                <Button @click="storeCollection" :label="$t('content.save')" class="p-button-primary shadow-sm" />
              </div>
            </TransitionFade>
          </div>
          <TransitionFade>
            <div class="flex flex-row items-center space-x-2" v-if="collectionSelected && $verifyPermission(permissions, ['v2.client.project.collection.update'])">
              <Button @click="openUpdateModal" :label="$t('content.settingBtn')" icon="pi pi-cog" class="p-button-light" />
            </div>
          </TransitionFade>
          <Dialog :header="$t('content.settings')" v-model:visible="updateCollectionModal" :style="{ width: '25vw' }" position="center" modal>
            <div class="form-group">
              <label for="collName" class="form-label">{{ $t("content.collectionName") }}:</label>
              <InputText id="collName" type="text" v-model="collectionSelectedName" class="p-inputtext-sm" />
            </div>
            <div class="flex justify-end items-center space-x-3">
              <Button @click="deleteCollection" :label="$t('content.delete')" icon="pi pi-trash" class="p-button-danger p-button-outlined p-buttom-sm" />
              <Button @click="showDuplicateModal" :label="$t('content.duplicate')" icon="pi pi-copy" class="p-button-light p-buttom-sm" />
              <Button @click="updateCollection" :label="$t('content.update')" icon="pi pi-save" class="p-button-primary p-buttom-sm" />
            </div>
          </Dialog>
          <Dialog :header="$t('content.settings')" v-model:visible="duplicateCollectionModal" :style="{ width: '25vw' }" position="center" modal>
            <div class="form-group">
              <label for="dupCollName" class="form-label">{{ $t("content.duplicateName") }}</label>
              <InputText id="dupCollName" type="text" v-model="collectionDuplicateName" class="p-inputtext-sm" />
            </div>
            <div class="flex justify-end items-center">
              <Button @click="duplicateCollection" :label="$t('content.saveDuplicate')" icon="pi pi-copy" class="p-button-primary p-buttom-sm" />
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import TransitionFade from "@/components/TransitionFade"
// import QueryBuilderContent from '@/components/QueryBuilderContent'
import $ from "jquery"
import QueryBuilder from "jQuery-QueryBuilder"
import QueryBuilderConfig from "@/config/QueryBuilderConfig"
import axiosClient from "@/config/AxiosClient"
import Dialog from "primevue/dialog"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import { mapGetters } from 'vuex'

export default {
  QueryBuilder,
  name: "WrapperContent",
  props: ["collection", "segment", "showTopNavbar"],
  emits: ["Filter-wrapper", "deleteCollection", "storeCollection", "getContent", "updateCollection"],
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  components: {
    TransitionFade,
    Dialog,
    Button,
    InputText
    // QueryBuilderContent
  },
  computed: {
    ...mapGetters({permissions: 'Projects/permissionsForProject'}),
    projectContentKeys(){
      return this.$store.state.Content.projectContentKeys
    }
  },
  data: function () {
    return {
      currentProject: this.$route.params.id,
      filterKeyword: "",
      collectionSelectedName: "Collections",
      collectionSelectedId: null,
      isMenuCollectionOpen: false,
      isSaveCollectionFormOpen: false,
      collectionSelected: false,
      storeCollectionBtn: false,
      collectionName: "",
      collectionDuplicateName: "",
      dataCollection: {},
      contentDetail: {
        data: {}
      },
      filterAdvancedBuilder: {
        query: null,
        rules: null,
        loaded: false
      },
      keys: [],
      collectionUpdated: false,
      copyDropdown: false,
      copyTypeFilter: 0,
      updateCollectionModal: false,
      duplicateCollectionModal: false,
      errorQueryBuilder: false
    }
  },
  async created() {
    let currentProject = this.currentProject
    if (currentProject) {
      await this.$store.dispatch("Content/getContentKeys", currentProject)
      $("#builder").queryBuilder("destroy")
      $(document).find("#builder").html("")
      if (!this.filterAdvancedBuilder.loaded) this.ShowQueryBuilder()
    }
  },
  methods: {
    search: function () {
      let query = QueryBuilderConfig.onFilterSearch(this.filterKeyword)
      this.$emit("Filter-wrapper", query)
    },
    ShowQueryBuilder: function () {
      this.filterAdvancedBuilder.loaded = true
      let Filters = null
      if(this.projectContentKeys){
        Filters = QueryBuilderConfig.ContentObjectKeys(this.projectContentKeys)
      }
      // let utils = $.fn.queryBuilder.constructor.utils;
      setTimeout(() => {
          try {
            $("#builder").queryBuilder({
              // mongoOperators: {
              //     equal:            function(v){ return {'$regex': '^'+utils.escapeRegExp(v[0])+'$', '$options': 'i' } },
              //     not_equal:        function(v){ return {'$regex': '^(?!'+utils.escapeRegExp(v[0])+'$).*$', '$options': 'i' } },
              //     begins_with:      function(v){ return {'$regex': '^' + utils.escapeRegExp(v[0]), '$options': 'i' }; },
              //     not_begins_with:  function(v){ return {'$regex': '^(?!' + utils.escapeRegExp(v[0]) + ')', '$options': 'i' }; },
              //     contains:         function(v){ return {'$regex': utils.escapeRegExp(v[0]), '$options': 'i' }; },
              //     not_contains:     function(v){ return {'$regex': '^((?!' + utils.escapeRegExp(v[0]) + ').)*$', '$options': 'si'}; },
              //     ends_with:        function(v){ return {'$regex': utils.escapeRegExp(v[0]) + '$', '$options': 'i' }; },
              //     not_ends_with:    function(v){ return {'$regex': '(?<!' + utils.escapeRegExp(v[0]) + ')$', '$options': 'i' }; },
              // },
              // mongoRuleOperators: {
              //     $regex: function(v) {
              //         v = v.$regex;
              //         if (v.slice(0, 4) == '^(?!' && v.slice(-5) == '$).*$') {
              //             return { 'val': v.slice(4, -5), 'op': 'not_equal' };
              //         } else if (v.slice(0, 4) == '^(?!' && v.slice(-1) == ')') {
              //             return { 'val': v.slice(4, -1), 'op': 'not_begins_with' };
              //         } else if (v.slice(0, 5) == '^((?!' && v.slice(-5) == ').)*$') {
              //             return { 'val': v.slice(5, -5), 'op': 'not_contains' };
              //         } else if (v.slice(0, 4) == '(?<!' && v.slice(-2) == ')$') {
              //             return { 'val': v.slice(4, -2), 'op': 'not_ends_with' };
              //         } else if (v.slice(0, 1) == '^' && v.slice(-1) == '$') {
              //             return { 'val': v.slice(1, -1), 'op': 'equal' };
              //         } else if (v.slice(-1) == '$') {
              //             return { 'val': v.slice(0, -1), 'op': 'ends_with' };
              //         } else if (v.slice(0, 1) == '^') {
              //             return { 'val': v.slice(1), 'op': 'begins_with' };
              //         } else {
              //             return { 'val': v, 'op': 'contains' };
              //         }
              //     }
              // },
              plugins: {
                sortable: { icon: "fas fa-ellipsis-v" },
                "not-group": { icon_checked: "far fa-check-circle", icon_unchecked: "far fa-circle" },
                "unique-filter": null
              },
              filters: Filters,
              icons: {
                add_group: "far fa-object-ungroup",
                add_rule: "fa fa-plus-circle",
                remove_group: "fa fa-minus-circle",
                remove_rule: "fa fa-minus-circle",
                error: "fa fa-exclamation-triangle",
                sortable: "fa fa-exclamation-triangle"
              }
            })

            // Modifications for the inputs between and not between
            $("#builder").on("afterUpdateRuleOperator.queryBuilder", function (event, rule) {
              if (rule.operator.type == "between" || rule.operator.type == "not_between") {
                rule.$el.find(".rule-value-container input").each(function (index, element) {
                  $(element).attr("style", "width: 35% !important")
                })
              }
            })

            let rules = typeof this.filterAdvancedBuilder.rules === "string" ? JSON.parse(this.filterAdvancedBuilder.rules) : this.filterAdvancedBuilder.rules

            this.filterAdvancedBuilder.rules ? $("#builder").queryBuilder("setRules", rules) : null
            this.errorQueryBuilder = false
        } catch (error) {
          this.errorQueryBuilder = true
        }
        })
    },
    getContentFiltered: function () {
      if (this.collectionSelected) this.collectionUpdated = true
      if (!this.collectionSelected) {
        this.collectionSelectedName = "Collections"
        this.collectionSelected = false
        this.storeCollectionBtn = true
        this.collectionUpdated = false
      }
      this.filterAdvancedBuilder.query = $("#builder").queryBuilder("getMongo")
      this.filterAdvancedBuilder.rules = $("#builder").queryBuilder("getRules")

      let query = this.filterAdvancedBuilder.query
      this.$emit("Filter-wrapper", query)
      this.ModalClose()
    },
    selectCollection: function (c) {
      this.storeCollectionBtn = false
      this.collectionSelected = true
      this.dataCollection.name = c.name
      this.dataCollection.id = c.id
      this.collectionSelectedName = c.name
      this.collectionSelectedId = c.id
      this.filterAdvancedBuilder.query = JSON.parse(c.query)
      this.filterAdvancedBuilder.rules = c.rules
      if (this.filterAdvancedBuilder.query) {
        this.closeMenuCollection()
        let query = this.filterAdvancedBuilder.query
        this.$emit("Filter-wrapper", query)
      }
      this.ShowQueryBuilder()
    },
    selectCollectionBasicSegment: function () {
      const newCollection = {}
      newCollection.name = this.collectionSelectedName
      newCollection.query = this.filterAdvancedBuilder.query
      newCollection.rules = this.filterAdvancedBuilder.rules

      //si la actualizo primero mostrar alerta de que se estara actualizando tambien en content, cerrar modal, dejar coleccion seleccionada
      if (this.collectionUpdated) {
        this.$swal({
          title: this.$t("utils.alerts.sure"),
          text: this.$t("utils.alerts.collectionText"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("utils.alerts.confirmButton"),
          cancelButtonText: this.$t("utils.alerts.cancelButton")
        }).then((result) => {
          if (result.isConfirmed) {
            this.updateCollection()
            this.$store.dispatch("BasicSegment/setCollection", newCollection)
          }
        })
      }
      //dejar esta coleccion seleccionada y cerrar modal
      if (this.filterAdvancedBuilder.query || this.filterAdvancedBuilder.rules) {
        this.$store.dispatch("BasicSegment/setCollection", newCollection)
        this.$store.dispatch("Menu/closeCollectionModal")
      } else
        return this.$swal({
          title: this.$t("utils.alerts.Oops"),
          text: this.$t("utils.alerts.selectCollectionFirts"),
          icon: "warning"
        })
    },
    storeCollection: function () {
      const newCollection = {}
      newCollection.name = this.collectionName
      newCollection.query = this.filterAdvancedBuilder.query
      newCollection.rules = this.filterAdvancedBuilder.rules
      newCollection.name != "" || newCollection.name != null || newCollection.rules ? this.$emit("storeCollection", newCollection) : null
      this.storeCollectionBtn = false
      this.closeSaveCollectionFormOpen()
    },
    updateCollection: async function () {
      const collection = {}
      collection.query = this.filterAdvancedBuilder.query
      collection.rules = this.filterAdvancedBuilder.rules

      await axiosClient.put(`${process.env.VUE_APP_URL_API}/api/2.0/collection/${this.collectionSelectedId}`, { name: this.collectionSelectedName })

      this.updateCollectionModal = false

      this.filterAdvancedBuilder.query || this.filterAdvancedBuilder.rules
        ? this.$emit("updateCollection", {
            collection,
            dataCollection: this.dataCollection
          })
        : null
    },
    showDuplicateModal: function () {
      this.duplicateCollectionModal = !this.duplicateCollectionModal
    },
    duplicateCollection: async function () {
      this.duplicateCollectionModal = !this.duplicateCollectionModal
      let res = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/collection/${this.collectionSelectedId}/duplicate`, { name: this.collectionDuplicateName })
      if (res.status == 200 || res.status == 204) {
        this.$toast.info(this.$t("utils.toast.collectionDupSuccess"))
      }
      if (res.status == 422 || res.status == 500) {
        this.$toast.error(this.$t("utils.toast.error"))
      }
    },
    deleteCollection: function () {
      if (this.dataCollection.id) {
        this.$swal({
          title: this.$t("utils.alerts.sure"),
          text: this.$t("utils.alerts.deleteText"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("utils.alerts.confirmButton"),
          cancelButtonText: this.$t("utils.alerts.cancelButton")
        }).then((result) => {
          if (result.isConfirmed) {
            this.$emit("deleteCollection", this.dataCollection)
            this.collectionSelectedName = ""
            this.resetFilter()
            this.updateCollectionModal = false
          }
        })
      }
    },
    resetFilter: function () {
      this.collectionSelectedName = "Collections"
      this.collectionSelectedId = null
      this.storeCollectionBtn = false
      this.collectionSelected = false
      $("#builder").queryBuilder("reset")
      let query = ""
      this.$emit("Filter-wrapper", query)
      this.filterKeyword = ""
      this.ModalClose()
    },
    closeSaveCollectionFormOpen: function () {
      this.isSaveCollectionFormOpen = false
      this.collectionName = ""
    },
    OpenModal: function () {
      this.$store.dispatch("Menu/openModalQueryFilter")
    },
    ModalClose: function () {
      this.$store.dispatch("Menu/closeModalQueryFilter")
      this.filterAdvancedBuilder.loaded = false
    },
    TypeSelected: function (newVal) {
      this.$store.dispatch("Audience/changeTypeSelected", newVal)
      this.$store.state.Menu.isModalCollectionOpen ? this.$store.dispatch("Menu/closeCollectionModal") : null
    },
    closeMenuCollection: function () {
      this.isMenuCollectionOpen = false
    },
    showCopyDropdown() {
      this.copyDropdown = true
    },
    closeCopyDropdown() {
      this.copyDropdown = false
    },
    copyType(state) {
      this.copyTypeFilter = state
      if (state == 1) {
        this.$copyText(`${process.env.VUE_APP_URL_API}/api/2.0/projects/${this.$store.state.Projects.activeProject.id}/content/2.0/xml`)
        this.$toast.info(this.$t("utils.toast.copyToClipboard"))
      }
      if (state == 2) {
        this.$copyText(`${process.env.VUE_APP_URL_API}/api/2.0/projects/${this.$store.state.Projects.activeProject.id}/content/json`)
        this.$toast.info(this.$t("utils.toast.copyToClipboard"))
      }
      if (state == 3) {
        this.$copyText(`${process.env.VUE_APP_URL_API}/api/2.0/projects/${this.$store.state.Projects.activeProject.id}/content/json/${this.collectionSelectedId}`)
        this.$toast.info(this.$t("utils.toast.copyToClipboard"))
      }
    },
    openUpdateModal() {
      this.updateCollectionModal = !this.updateCollectionModal
    }
  }
}
</script>

<style scope>
.swal2-container {
  z-index: 1130 !important;
}
</style>
