<template>
  <div>
    <!--Wrapper - Subnavs nad QB-->
    <WrapperContent :collection="$store.state.Audience.collection" @Filter-wrapper="onFilterWrapper" @deleteCollection="deleteCollection" @storeCollection="storeCollection" @getContent="getContent" @updateCollection="updateCollection" @getCollections="getCollections" />
    <!--Products info modal-->
    <vue-final-modal v-model="$store.state.Menu.isContentCardModalOpen" @keydown.esc="ModalClose" tabindex="0">
      <div class="h-screen">
        <div class="flex flex-col h-full items-center justify-center">
          <div v-if="!isActiveItemInfo" class="text-gray-200 font-semibold tracking-wide uppercase">{{ t("content.loading", {}, { locale: $store.state.Langs.selected.lang }) }}</div>
          <ProductsInfoModal :isActiveItemInfo="isActiveItemInfo" @ModalClose="ModalClose" :itemInfo="itemInfo" />
        </div>
      </div>
    </vue-final-modal>
    <!--Main content - Products cards-->
    <div>
      <TransitionFade>
        <div v-if="!currentProject">
          <NoContent />
        </div>
      </TransitionFade>
      <div>
        <div class="container-xl px-2 py-2 mx-auto w-full my-4 flex flex-col">
          <div class="flex flex-col items-center mb-2 w-full" v-if="$store.state.Content.isProjectContentActive">
            <PaginationSelect :current_page="$store.state.Content.projectContentMeta.current_page" :from="$store.state.Content.projectContentMeta.from" :last_page="$store.state.Content.projectContentMeta.last_page" :path="$store.state.Content.projectContentMeta.path" :per_page="$store.state.Content.projectContentMeta.per_page" :to="$store.state.Content.projectContentMeta.to" :total="$store.state.Content.total" @change-page="paginationChange" :dropdown="true" />
          </div>
          <CardContentSkeleton v-if="$store.state.Content.loading" />
          <ul class="grid grid-cols-12 gap-4" v-if="$store.state.Content.isProjectContentActive">
            <li class="relative flex-1 col-span-6 sm:col-span-6 md:col-span-4 lg:col-span-4 xl:col-span-2" v-for="article in $store.state.Content.projectContent" :key="article.item_id">
              <CardContent :article="article" @ModalOpen="showItemInfo" />
            </li>
            <li class="text-center my-5 w-full col-span-12" v-if="$store.state.Content.projectContent.length === 0">
              <NoResultsFound>
                <div>
                  <h1 class="text-gray-600 font-semibold text-2xl uppercase">{{ t("content.notFound", {}, { locale: $store.state.Langs.selected.lang }) }}</h1>
                  <p class="text-md text-gray-400" v-if="hasFilter">{{ t("content.filter", {}, { locale: $store.state.Langs.selected.lang }) }}</p>
                </div>
              </NoResultsFound>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import TransitionFade from "@/components/TransitionFade"
import WrapperContent from "@/components/Content/WrapperContent"
import PaginationSelect from "@/components/PaginationSelect"
import NoContent from "@/components/Utils/NoContent"
import ProductsInfoModal from "@/components/Content/ProductsInfoModal"
import CardContent from "@/components/Content/CardContent"
import CardContentSkeleton from "@/components/Content/CardContentSkeleton"
import NoResultsFound from "@/components/Utils/NoResultsFound"

export default {
  name: "Content",
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  components: {
    TransitionFade,
    WrapperContent,
    PaginationSelect,
    NoContent,
    ProductsInfoModal,
    CardContent,
    CardContentSkeleton,
    NoResultsFound
  },
  data: function () {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      pag: 1,
      filterKeyword: "",
      default: {
        limit: 100
      },
      isActiveItemInfo: false,
      itemInfo: null,
      hasDiscount: false,
      hasFilter: false
    }
  },
  created() {
    if (this.currentProject) {
      this.getContent()
      this.getCollections()
    }
  },
  methods: {
    onFilterWrapper: function (value) {
      if (value === "") this.hasFilter = false
      else if (value) this.hasFilter = true
      this.filterKeyword = value
      this.currentProject ? this.getContent() : null
    },
    getContent: function (config = {}) {
      let filter = {}
      filter.q = this.filterKeyword || null
      filter.page = config.page || 1
      filter.limit = config.limit || this.default.limit
      this.$store.dispatch("Content/getContent", { currentProject: this.currentProject, filter }).then((res) => {
        if (res.status == 403) {
          this.$toast.error(`${res.data.message}`)
          this.$router.push({
            name: "Projects",
            params: { id: "" }
          })
        }
      })
    },
    paginationChange: function (page) {
      this.getContent({ page })
    },
    showItemInfo: function (value) {
      this.isActiveItemInfo = false
      this.itemInfo = []
      this.ModalOpen()
      this.$store.dispatch("Content/getItemContent", { currentProject: this.currentProject, id: value.item_id, source: value.source }).then((res) => {
        if (res.status == 404) this.$toast.error("Error, please try again in a few minutes")
        if (res.status == 200) (this.isActiveItemInfo = true), (this.itemInfo = res.data)
      })
    },
    getCollections: function () {
      this.$store.dispatch("Audience/getCollection", this.currentProject)
    },
    storeCollection: function (query) {
      this.$store.dispatch("Content/storeCollection", { collectionSaved: query, currentProject: this.currentProject }).then((res) => {
        if (res.status == 204) this.$toast.success(`Collection ${query.name} was stored successfully`)
        if (res.status == 500 || res.status == 401 || res.status == 422) this.$toast.error("Error, please try again in a few minutes")
        this.getCollections()
        this.closeModalCollection()
      })
    },
    updateCollection({ collection, dataCollection }) {
      this.$store.dispatch("Content/updateCollection", { collectionSaved: collection, currentProject: this.currentProject, id: dataCollection.id }).then((res) => {
        if (res.status == 204) this.getCollections(), this.getContent(), this.$toast.success(`Collection ${dataCollection.name} was updated successfully`)
        if (res.status == 500 || res.status == 401 || res.status == 422) this.$toast.error("Error, please try again in a few minutes")
        this.closeModalCollection()
      })
    },
    deleteCollection: function (datacollection) {
      this.$store.dispatch("Content/deleteCollection", { id: datacollection.id, currentProject: this.currentProject }).then((res) => {
        if (res.status == 204) this.$toast.success(`Collection ${datacollection.name} was deleted successfully`)
        if (res.status == 500 || res.status == 401 || res.status == 422) this.$toast.error("Error, please try again in a few minutes")
        this.getCollections(), this.getContent(), this.closeModalCollection()
      })
    },
    ModalOpen: function () {
      this.$store.dispatch("Menu/openContentCardModal")
    },
    ModalClose: function () {
      this.$store.dispatch("Menu/closeContentCardModal")
    },
    closeModalCollection: function () {
      this.$store.dispatch("Menu/closeCollectionModal")
    }
  }
}
</script>
