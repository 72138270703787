// import $ from "jquery"

const QueryBuilderConfig = {
  onFilterSearch: function (filterKeyword) {
    let query = null
    if (filterKeyword !== "") {
      query = {
        $or: [{ name: { $regex: `${filterKeyword}`, $options: "i" } }, { brand_id: { $regex: `${filterKeyword}`, $options: "i" } }, { description: { $regex: `${filterKeyword}`, $options: "i" } }, { "categories.category_name": { $regex: `${filterKeyword}`, $options: "i" } }]
      }
    }
    return query
  },
  ContentObjectKeys: function (keys) {
    let keyFilters = []
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] == 'stock' || keys[i] == 'available_quantity') {
        keyFilters = [
          ...keyFilters,
          {
            id: keys[i],
            label: keys[i],
            type: "integer",
          }
        ]
      } else {
        keyFilters = [
          ...keyFilters,
          {
            id: keys[i],
            label: keys[i],
            type: "string",
            operators: ["equal", "not_equal", "in", "not_in", "less", "less_or_equal", "greater", "greater_or_equal", "between", "not_between", "begins_with", "not_begins_with", "contains", "not_contains", "ends_with", "not_ends_with", "is_empty", "is_not_empty", "is_null", "is_not_null"]
          }
        ]
      }
    }
    return keyFilters
  },
  AudienceObjectKeys: function (keys) {
    let arr = [
      {
        id: "firstname",
        label: "firstname",
        type: "string",
        value_separator: ","
      },
      {
        id: "lastname",
        label: "lastname",
        type: "string",
        value_separator: ","
      },
      {
        id: "email",
        label: "email",
        type: "string",
        value_separator: ","
      },
      {
        id: "phone",
        label: "phone",
        type: "string",
        value_separator: ","
      },
      {
        id: "sources",
        label: "sources",
        type: "string",
        value_separator: ","
      },
      {
        id: "buyer_type",
        label: "buyer_type",
        type: "string",
        input: "select",
        multiple: true,
        // plugin: 'select2',
        // plugin_config: {
        //     theme: 'bootstrap4',
        //     closeOnSelect: false,
        //     allowClear: true,
        //     placeholder: "SELECCIONE",
        // },
        values: {
          "": "",
          online: "online",
          offline: "offline",
          omnichannel: "omnichannel"
        },
        operators: ["in", "not_in"]
      },
      {
        id: "revenue_total",
        label: "revenue_total",
        type: "integer",
        value_separator: ","
      },
      {
        id: "revenue_online",
        label: "revenue_online",
        type: "integer",
        value_separator: ","
      },
      {
        id: "revenue_offline",
        label: "revenue_offline",
        type: "integer",
        value_separator: ","
      },
      {
        id: "revenue_avg",
        label: "revenue_avg",
        type: "integer",
        value_separator: ","
      },
      {
        id: "revenue_avg_online",
        label: "revenue_avg_online",
        type: "integer",
        value_separator: ","
      },
      {
        id: "revenue_avg_offline",
        label: "revenue_avg_offline",
        type: "integer",
        value_separator: ","
      },
      {
        id: "sales",
        label: "sales",
        type: "integer",
        value_separator: ","
      },
      {
        id: "sales_offline",
        label: "sales_offline",
        type: "integer",
        value_separator: ","
      },
      {
        id: "sales_online",
        label: "sales_online",
        type: "integer",
        value_separator: ","
      },
      {
        id: "document",
        label: "document",
        type: "string",
        value_separator: ","
      },
      {
        id: "document_type",
        label: "document_type",
        type: "string",
        value_separator: ","
      },
      {
        id: "last_buy_online",
        label: "last_buy_online",
        type: "string",
        operators: ["less", "less_or_equal", "greater", "greater_or_equal", "between", "not_between"]
        // valueSetter: function (rule, value) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             let newVal = value[i].replace("DAYS_", "").replace(/@@/g, "")
        //             $(this).val(newVal)
        //         })
        //     }
        //     return value
        // },
        // valueGetter: function (rule) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     let value = []
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             if ($(this).val().indexOf("-") > -1) {
        //                 value.push($(this).val());
        //             } else {
        //                 value.push(`@@DAYS_${$(this).val()}@@`);
        //             }
        //         });
        //     }
        //     return value;
        // }
      },
      {
        id: "last_buy_offline",
        label: "last_buy_offline",
        type: "string",
        operators: ["less", "less_or_equal", "greater", "greater_or_equal", "between", "not_between"]
        // valueSetter: function (rule, value) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             let newVal = value[i].replace("DAYS_", "").replace(/@@/g, "")
        //             $(this).val(newVal)
        //         })
        //     }
        //     return value
        // },
        // valueGetter: function (rule) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     let value = []
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             if ($(this).val().indexOf("-") > -1) {
        //                 value.push($(this).val());
        //             } else {
        //                 value.push(`@@DAYS_${$(this).val()}@@`);
        //             }
        //         });
        //     }
        //     return value;
        // }
      },
      {
        id: "last_buy",
        label: "last_buy",
        type: "string",
        operators: ["less", "less_or_equal", "greater", "greater_or_equal", "between", "not_between"]
        // valueSetter: function (rule, value) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             let newVal = value[i].replace("DAYS_", "").replace(/@@/g, "")
        //             $(this).val(newVal)
        //         })
        //     }
        //     return value
        // },
        // valueGetter: function (rule) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     let value = []
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             if ($(this).val().indexOf("-") > -1) {
        //                 value.push($(this).val());
        //             } else {
        //                 value.push(`@@DAYS_${$(this).val()}@@`);
        //             }
        //         });
        //     }
        //     return value;
        // }
      },
      {
        id: "first_buy_online",
        label: "first_buy_online",
        type: "string",
        operators: ["less", "less_or_equal", "greater", "greater_or_equal", "between", "not_between"]
        // valueSetter: function (rule, value) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             let newVal = value[i].replace("DAYS_", "").replace(/@@/g, "")
        //             $(this).val(newVal)
        //         })
        //     }
        //     return value
        // },
        // valueGetter: function (rule) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     let value = []
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             if ($(this).val().indexOf("-") > -1) {
        //                 value.push($(this).val());
        //             } else {
        //                 value.push(`@@DAYS_${$(this).val()}@@`);
        //             }
        //         });
        //     }
        //     return value;
        // }
      },
      {
        id: "first_buy_offline",
        label: "first_buy_offline",
        type: "string",
        operators: ["less", "less_or_equal", "greater", "greater_or_equal", "between", "not_between"]
        // valueSetter: function (rule, value) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             let newVal = value[i].replace("DAYS_", "").replace(/@@/g, "")
        //             $(this).val(newVal)
        //         })
        //     }
        //     return value
        // },
        // valueGetter: function (rule) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     let value = []
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             if ($(this).val().indexOf("-") > -1) {
        //                 value.push($(this).val());
        //             } else {
        //                 value.push(`@@DAYS_${$(this).val()}@@`);
        //             }
        //         });
        //     }
        //     return value;
        // }
      },
      {
        id: "first_buy",
        label: "first_buy",
        type: "string",
        operators: ["less", "less_or_equal", "greater", "greater_or_equal", "between", "not_between"]
        // valueSetter: function (rule, value) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             let newVal = value[i].replace("DAYS_", "").replace(/@@/g, "")
        //             $(this).val(newVal)
        //         })
        //     }
        //     return value
        // },
        // valueGetter: function (rule) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     let value = []
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             if ($(this).val().indexOf("-") > -1) {
        //                 value.push($(this).val());
        //             } else {
        //                 value.push(`@@DAYS_${$(this).val()}@@`);
        //             }
        //         });
        //     }
        //     return value;
        // }
      },
      {
        id: "last_order.sale_id",
        label: "last_order.sale_id",
        type: "string"
      },
      {
        id: "last_order.status",
        label: "last_order.status",
        type: "string",
        input: "select",
        multiple: true,
        // plugin: 'select2',
        // plugin_config: {
        //     theme: 'bootstrap4',
        //     closeOnSelect: false,
        //     allowClear: true,
        //     placeholder: "SELECCIONE",
        // },
        values: {
          "": "",
          handling: "handling",
          "cancellation-requested": "cancellation-requested",
          "payment-pending": "payment-pending",
          canceled: "canceled",
          "on-order-completed": "on-order-completed",
          invoiced: "invoiced",
          "payment-approved": "payment-approved",
          invoice: "invoice",
          "ready-for-handling": "ready-for-handling"
        },
        operators: ["in", "not_in"]
      },
      {
        id: "last_order.channel",
        label: "last_order.channel",
        type: "string",
        input: "select",
        multiple: true,
        // plugin: 'select2',
        // plugin_config: {
        //     theme: 'bootstrap4',
        //     closeOnSelect: false,
        //     allowClear: true,
        //     placeholder: "SELECCIONE",
        // },
        values: {
          "": "",
          online: "online",
          offline: "offline"
        },
        operators: ["in", "not_in"]
      },
      {
        id: "last_order.shippings.method.shippingCount",
        label: "last_order.shippings.method.shippingCount",
        type: "integer"
      },
      {
        id: "last_order.created_at",
        label: "last_order.created_at",
        type: "string",
        operators: ["less", "less_or_equal", "greater", "greater_or_equal", "between", "not_between"]
        // valueSetter: function (rule, value) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             let newVal = value[i].replace("DAYS_", "").replace(/@@/g, "")
        //             $(this).val(newVal)
        //         })
        //     }
        //     return value
        // },
        // valueGetter: function (rule) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     let value = []
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             if ($(this).val().indexOf("-") > -1) {
        //                 value.push($(this).val());
        //             } else {
        //                 value.push(`@@DAYS_${$(this).val()}@@`);
        //             }
        //         });
        //     }
        //     return value;
        // }
      },
      {
        id: "last_order_online.sale_id",
        label: "last_order_online.sale_id",
        type: "string"
      },
      {
        id: "last_order_online.status",
        label: "last_order_online.status",
        type: "string",
        input: "select",
        multiple: true,
        // plugin: 'select2',
        // plugin_config: {
        //     theme: 'bootstrap4',
        //     closeOnSelect: false,
        //     allowClear: true,
        //     placeholder: "SELECCIONE",
        // },
        values: {
          "": "",
          handling: "handling",
          "cancellation-requested": "cancellation-requested",
          "payment-pending": "payment-pending",
          canceled: "canceled",
          "on-order-completed": "on-order-completed",
          invoiced: "invoiced",
          "payment-approved": "payment-approved",
          invoice: "invoice",
          "ready-for-handling": "ready-for-handling"
        },
        operators: ["in", "not_in"]
      },
      {
        id: "last_order_online.channel",
        label: "last_order_online.channel",
        type: "string",
        input: "select",
        multiple: true,
        // plugin: 'select2',
        // plugin_config: {
        //     theme: 'bootstrap4',
        //     closeOnSelect: false,
        //     allowClear: true,
        //     placeholder: "SELECCIONE",
        // },
        values: {
          "": "",
          online: "online",
          offline: "offline"
        },
        operators: ["in", "not_in"]
      },
      {
        id: "last_order_online.shippings.method.shippingCount",
        label: "last_order_online.shippings.method.shippingCount",
        type: "integer"
      },
      {
        id: "last_order_online.created_at",
        label: "last_order_online.created_at",
        type: "string",
        operators: ["less", "less_or_equal", "greater", "greater_or_equal", "between", "not_between"]
        // valueSetter: function (rule, value) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             let newVal = value[i].replace("DAYS_", "").replace(/@@/g, "")
        //             $(this).val(newVal)
        //         })
        //     }
        //     return value
        // },
        // valueGetter: function (rule) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     let value = []
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             if ($(this).val().indexOf("-") > -1) {
        //                 value.push($(this).val());
        //             } else {
        //                 value.push(`@@DAYS_${$(this).val()}@@`);
        //             }
        //         });
        //     }
        //     return value;
        // }
      },
      {
        id: "last_order_offline.sale_id",
        label: "last_order_offline.sale_id",
        type: "string"
      },
      {
        id: "last_order_offline.store.external_id",
        label: "last_order_offline.store.external_id",
        type: "string",
        value_separator: ",",
        operators: ["in", "not_in"],
        // valueSetter: function (rule, value) {
        //   let operator = rule.operator
        //   let container = rule.$el.find(".rule-value-container")
        //   console.log("setter")
        //   console.log(value)
        //   for (let i = 0; i < operator.nb_inputs; i++) {
        //     container.find("[name=" + rule.id + "_value_" + i + "]").each(function () {
        //       $(this).val(value)
        //     })
        //   }
        //   return value
        // },
        // valueGetter: function (rule) {
        //   let operator = rule.operator
        //   let container = rule.$el.find(".rule-value-container")
        //   let value = []
        //   console.log("getter")
        //   console.log(value)
        //   for (let i = 0; i < operator.nb_inputs; i++) {
        //     container.find("[name=" + rule.id + "_value_" + i + "]").each(function () {
        //       console.log($(this).val())
        //       value.push($(this).val())
        //     })
        //   }
        //   return value
        // }
      },
      {
        id: "last_order_offline.status",
        label: "last_order_offline.status",
        type: "string",
        input: "select",
        multiple: true,
        // plugin: 'select2',
        // plugin_config: {
        //     theme: 'bootstrap4',
        //     closeOnSelect: false,
        //     allowClear: true,
        //     placeholder: "SELECCIONE",
        // },
        values: {
          "": "",
          handling: "handling",
          "cancellation-requested": "cancellation-requested",
          "payment-pending": "payment-pending",
          canceled: "canceled",
          "on-order-completed": "on-order-completed",
          invoiced: "invoiced",
          "payment-approved": "payment-approved",
          invoice: "invoice",
          "ready-for-handling": "ready-for-handling"
        },
        operators: ["in", "not_in"]
      },
      {
        id: "last_order_offline.channel",
        label: "last_order_offline.channel",
        type: "string",
        input: "select",
        multiple: true,
        // plugin: 'select2',
        // plugin_config: {
        //     theme: 'bootstrap4',
        //     closeOnSelect: false,
        //     allowClear: true,
        //     placeholder: "SELECCIONE",
        // },
        values: {
          "": "",
          online: "online",
          offline: "offline"
        },
        operators: ["in", "not_in"]
      },
      {
        id: "last_order_offline.shippings.method.shippingCount",
        label: "last_order_offline.shippings.method.shippingCount",
        type: "integer"
      },
      {
        id: "last_order_offline.created_at",
        label: "last_order_offline.created_at",
        type: "string",
        operators: ["less", "less_or_equal", "greater", "greater_or_equal", "between", "not_between"]
        // valueSetter: function (rule, value) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             let newVal = value[i].replace("DAYS_", "").replace(/@@/g, "")
        //             $(this).val(newVal)
        //         })
        //     }
        //     return value
        // },
        // valueGetter: function (rule) {
        //     let operator = rule.operator
        //     let container = rule.$el.find('.rule-value-container')
        //     let value = []
        //     for (let i = 0; i < operator.nb_inputs; i++) {
        //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
        //             if ($(this).val().indexOf("-") > -1) {
        //                 value.push($(this).val());
        //             } else {
        //                 value.push(`@@DAYS_${$(this).val()}@@`);
        //             }
        //         });
        //     }
        //     return value;
        // }
      }
    ]

    keys.forEach((key) => {
      if (arr.findIndex((obj) => obj.id == key.key) == -1) {
        arr.push({
          id: key.key,
          label: key.label,
          type: key.type || "string",
          value_separator: ",",
          operators: ["equal", "not_equal", "in", "not_in", "less", "less_or_equal", "greater", "greater_or_equal", "between", "not_between", "begins_with", "not_begins_with", "contains", "not_contains", "ends_with", "not_ends_with", "is_empty", "is_not_empty", "is_null", "is_not_null"]
        })
      }
    })
    return arr
  },
  SalesObjectKeys: function (collection) {
    let collection_values = {}
    collection.forEach((c) => {
      collection_values[`@@COLLECTION_${c.id}@@`] = c.name
    })
    let keyFilters = []
    for (let i = 0; i < this.HardFilterSales.length; i++) {
      if (this.HardFilterSales[i] == "items.sku") {
        keyFilters = [
          ...keyFilters,
          {
            id: this.HardFilterSales[i],
            label: "collection",
            type: "string",
            input: "select",
            multiple: false,
            // plugin: 'select2',
            // plugin_config: {
            //     theme: 'bootstrap4',
            //     closeOnSelect: true,
            //     allowClear: true,
            //     placeholder: "SELECCIONE",
            // },
            values: collection_values,
            operators: this.operator_group_two
          }
        ]
      } else if (this.HardFilterSales[i] == "channel") {
        keyFilters = [
          ...keyFilters,
          {
            id: this.HardFilterSales[i],
            label: this.HardFilterSales[i],
            type: "string",
            input: "select",
            multiple: true,
            // plugin: 'select2',
            // plugin_config: {
            //     theme: 'bootstrap4',
            //     closeOnSelect: false,
            //     allowClear: true,
            //     placeholder: "SELECCIONE",
            // },
            values: this.values_group_three,
            operators: this.operator_group_two
          }
        ]
      } else if (this.HardFilterSales[i] == "status") {
        keyFilters = [
          ...keyFilters,
          {
            id: this.HardFilterSales[i],
            label: this.HardFilterSales[i],
            type: "string",
            input: "select",
            multiple: true,
            // plugin: 'select2',
            // plugin_config: {
            //     theme: 'bootstrap4',
            //     closeOnSelect: false,
            //     allowClear: true,
            //     placeholder: "SELECCIONE",
            // },
            values: this.values_group_two,
            operators: this.operator_group_two
          }
        ]
      } else {
        keyFilters = [
          ...keyFilters,
          {
            id: this.HardFilterSales[i],
            label: this.HardFilterSales[i],
            type: "string",
            operators: this.operator_group_one
            // valueSetter: function (rule, value) {
            //     let operator = rule.operator
            //     let container = rule.$el.find('.rule-value-container')
            //     for (let i = 0; i < operator.nb_inputs; i++) {
            //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
            //             let newVal = value[i].replace("DAYS_", "").replace(/@@/g, "")
            //             $(this).val(newVal)
            //         })
            //     }
            //     return value
            // },
            // valueGetter: function (rule) {
            //     let operator = rule.operator
            //     let container = rule.$el.find('.rule-value-container')
            //     let value = []
            //     for (let i = 0; i < operator.nb_inputs; i++) {
            //         container.find('[name=' + rule.id + '_value_' + i + ']').each(function () {
            //             if ($(this).val().indexOf("-") > -1) {
            //                 value.push($(this).val());
            //             } else {
            //                 value.push(`@@DAYS_${$(this).val()}@@`);
            //             }
            //         });
            //     }
            //     return value;
            // }
          }
        ]
      }
    }
    return keyFilters
  },
  //configs at sales object keys for loop
  HardFilterSales: ["items.sku", "channel", "status", "created_at"],

  values_group_one: { "": "", online: "online", offline: "offline", omnichannel: "omnichannel" },
  values_group_two: { "": "", handling: "handling", "cancellation-requested": "cancellation-requested", "payment-pending": "payment-pending", canceled: "canceled", "on-order-completed": "on-order-completed", invoiced: "invoiced", "payment-approved": "payment-approved", invoice: "invoice", "ready-for-handling": "ready-for-handling" },
  values_group_three: { "": "", online: "online", offline: "offline" },

  operator_group_one: ["less", "less_or_equal", "greater", "greater_or_equal", "between", "not_between"],
  operator_group_two: ["in", "not_in"],

  plugins: {
    sortable: { icon: "fas fa-ellipsis-v" },
    "not-group": {
      icon_checked: "far fa-check-circle",
      icon_unchecked: "far fa-circle"
    },
    "unique-filter": null
  }
}
export default QueryBuilderConfig
