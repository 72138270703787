<template>
  <div class="flex flex-row items-center justify-center">
    <div v-if="data?.data?.profiles_reached">
      <span class="w-auto text-red-600 font-medium text-md mr-3" v-if="data?.data?.error">{{ $t("Error, please, repeat the action.") }}</span>
      <span class="w-auto text-gray-600 font-medium text-md mr-3" v-else>
        {{ $t("Profiles found") }}: <span class="inline-flex rounded-full bg-gray-100 py-1 px-3 font-semibold leading-5 text-gray-600">{{ $filters.number(data?.data?.profiles_reached ? data?.data?.profiles_reached : 0) }}</span>
      </span>
    </div>
    <Button @click="activeSimulation" :label="loading ? $t('Simulating') : $t('Simulate')" :loading="loading" type="button" icon="pi pi-play" iconPos="left" class="p-button-light shadow-sm" />
  </div>
</template>

<script>
import Button from "primevue/button"

export default {
  props: ["data", "loading"],
  emits: ["simulateSegment"],
  components: {
    Button
  },
  methods: {
    activeSimulation: function () {
      this.$emit("simulateSegment")
    }
  }
}
</script>

<style lang="scss" scoped></style>
s
