<template>
<svg xmlns="http://www.w3.org/2000/svg" class="w-6 stroke-current text-gray-500 group-hover:bg-green-100 group-hover:text-green-800 transition" stroke-width="2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
  <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
</svg>
</template>

<script>
export default {
  name: `DropdownOptionDelete`,
};
</script>