<template>
  <div class="w-full flex flex-col items-start">
    <div class="border-b-2 border-blue-100 bg-gray-50 w-full">
      <Title title="Transactions" />
    </div>
    <div class="lg:flex lg:flex-col lg:justify-start lg:items-center space-y-6 lg:space-y-0 h-full w-full">
      <div class="grid grid-cols-12 gap-8 w-full">
        <!--PURCHASES QUANTITY-->
        <div class="col-span-12">
          <label class="inline-flex items-center cursor-pointer">
            <input type="checkbox" class="form-checkbox h-5 w-5 text-orange-600 cursor-pointer" checked />
            <span class="label">Purchases Quantity</span>
          </label>
          <div class="w-full mt-3 space-y-3 text-center">
            <div class="mt-10 block">
              <Slider v-model="purchasesQuantityValue" />
            </div>
            <div class="block pt-1">
              <div class="flex flex-row items-center justify-end gap-8">
                <div>
                  <span class="text-gray-500 text-sm">Min</span>
                  <div class="rounded-md">
                    <button class="bg-gray-200 text-gray-800 h-7 w-6 focus:outline-none rounded-l-md font-semibold" @click="changePruchaseQuantityValue({ type: 'rest', index: 0 })">-</button>
                    <input v-model.number="purchasesQuantityValue[0]" class="h-7 w-10 focus:outline-none p-1 text-center border border-gray-200 text-gray-800" />
                    <button class="bg-gray-200 text-gray-800 h-7 w-6 focus:outline-none rounded-r-md font-semibold" @click="changePruchaseQuantityValue({ type: 'sum', index: 0 })">+</button>
                  </div>
                </div>
                <div>
                  <span class="text-gray-500 text-sm">Max</span>
                  <div class="rounded-md">
                    <button class="bg-gray-200 text-gray-800 h-7 w-6 focus:outline-none rounded-l-md font-semibold" @click="changePruchaseQuantityValue({ type: 'rest', index: 1 })">-</button>
                    <input v-model.number="purchasesQuantityValue[1]" class="h-7 w-10 focus:outline-none p-1 text-center border border-gray-200 text-gray-800" />
                    <button class="bg-gray-200 text-gray-800 h-7 w-6 focus:outline-none rounded-r-md font-semibold" @click="changePruchaseQuantityValue({ type: 'sum', index: 1 })">+</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--PURCHASE AMOUNT-->
        <div class="col-span-12 my-5">
          <label class="inline-flex items-center cursor-pointer">
            <input type="checkbox" class="form-checkbox h-5 w-5 text-orange-600 cursor-pointer" checked />
            <span class="label">Amount Purchase</span>
          </label>
          <div class="w-full mt-3 space-y-3 text-center">
            <div class="mt-10 block">
              <Slider v-model="purchaseAmountValue" />
            </div>
            <div class="block pt-1">
              <div class="flex flex-row items-center justify-end gap-8">
                <div>
                  <span class="text-gray-500 text-sm">Min</span>
                  <div class="rounded-md">
                    <button class="bg-gray-200 text-gray-800 h-7 w-6 focus:outline-none rounded-l-md font-semibold" @click="changePurchaseAmountValue({ type: 'rest', index: 0 })">-</button>
                    <input v-model.number="purchaseAmountValue[0]" class="h-7 w-10 focus:outline-none p-1 text-center border border-gray-200 text-gray-800" />
                    <button class="bg-gray-200 text-gray-800 h-7 w-6 focus:outline-none rounded-r-md font-semibold" @click="changePurchaseAmountValue({ type: 'sum', index: 0 })">+</button>
                  </div>
                </div>
                <div>
                  <span class="text-gray-500 text-sm">Max</span>
                  <div class="rounded-md">
                    <button class="bg-gray-200 text-gray-800 h-7 w-6 focus:outline-none rounded-l-md font-semibold" @click="changePurchaseAmountValue({ type: 'rest', index: 1 })">-</button>
                    <input v-model.number="purchaseAmountValue[1]" class="h-7 w-10 focus:outline-none p-1 text-center border border-gray-200 text-gray-800" />
                    <button class="bg-gray-200 text-gray-800 h-7 w-6 focus:outline-none rounded-r-md font-semibold" @click="changePurchaseAmountValue({ type: 'sum', index: 1 })">+</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--AVERAGE TICKET-->
        <div class="col-span-12 my-5">
          <label class="inline-flex items-center cursor-pointer">
            <input type="checkbox" class="form-checkbox h-5 w-5 text-orange-600 cursor-pointer" checked />
            <span class="label">Amount Average</span>
          </label>
          <div class="w-full mt-3 space-y-3 text-center">
            <div class="mt-10 block">
              <Slider v-model="averageTicketValue" />
            </div>
            <div class="block pt-1">
              <div class="flex flex-row items-center justify-end gap-8">
                <div>
                  <span class="text-gray-500 text-sm">Min</span>
                  <div class="rounded-md">
                    <button class="bg-gray-200 text-gray-800 h-7 w-6 focus:outline-none rounded-l-md font-semibold" @click="changeAverageValue({ type: 'rest', index: 0 })">-</button>
                    <input v-model.number="averageTicketValue[0]" class="h-7 w-10 focus:outline-none p-1 text-center border border-gray-200 text-gray-800" />
                    <button class="bg-gray-200 text-gray-800 h-7 w-6 focus:outline-none rounded-r-md font-semibold" @click="changeAverageValue({ type: 'sum', index: 0 })">+</button>
                  </div>
                </div>
                <div>
                  <span class="text-gray-500 text-sm">Max</span>
                  <div class="rounded-md">
                    <button class="bg-gray-200 text-gray-800 h-7 w-6 focus:outline-none rounded-l-md font-semibold" @click="changeAverageValue({ type: 'rest', index: 1 })">-</button>
                    <input v-model.number="averageTicketValue[1]" class="h-7 w-10 focus:outline-none p-1 text-center border border-gray-200 text-gray-800" />
                    <button class="bg-gray-200 text-gray-800 h-7 w-6 focus:outline-none rounded-r-md font-semibold" @click="changeAverageValue({ type: 'sum', index: 1 })">+</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "@vueform/slider"
import Title from "@/components/Audience/Basic/Title"

export default {
  components: {
    Slider,
    Title
  },
  data() {
    return {
      purchaseAmountValue: [20, 40],
      purchasesQuantityValue: [20, 40],
      averageTicketValue: [20, 40]
    }
  },
  methods: {
    changeAverageValue: function ({ type, index }) {
      if (type == "sum" && index == 0) this.averageTicketValue[0]++
      if (type == "rest" && index == 0) this.averageTicketValue[0]--
      if (type == "sum" && index == 1) this.averageTicketValue[1]++
      if (type == "rest" && index == 1) this.averageTicketValue[1]--
    },
    changePurchaseAmountValue: function ({ type, index }) {
      if (type == "sum" && index == 0) this.purchaseAmountValue[0]++
      if (type == "rest" && index == 0) this.purchaseAmountValue[0]--
      if (type == "sum" && index == 1) this.purchaseAmountValue[1]++
      if (type == "rest" && index == 1) this.purchaseAmountValue[1]--
    },
    changePruchaseQuantityValue: function ({ type, index }) {
      if (type == "sum" && index == 0) this.purchasesQuantityValue[0]++
      if (type == "rest" && index == 0) this.purchasesQuantityValue[0]--
      if (type == "sum" && index == 1) this.purchasesQuantityValue[1]++
      if (type == "rest" && index == 1) this.purchasesQuantityValue[1]--
    }
  }
}
</script>

<style src="@vueform/slider/themes/default.css"></style>
