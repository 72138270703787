<script setup>
import { defineEmits } from "vue"
import { Icon } from "@iconify/vue"
import Button from "primevue/button"

const emit = defineEmits(["openModalCreateSegment"])

const openModal = function () {
  emit("openModalCreateSegment")
}
</script>

<template>
  <div class="text-center my-10">
    <Icon icon="tabler:article-off" class="mx-auto h-12 w-12 text-gray-400" />
    <h3 class="mt-2 text-sm font-medium text-gray-900">{{ $t("emptySegments") }}</h3>
    <p class="mt-1 text-sm text-gray-500">{{ $t("getStartedByCreatingASegment") }}</p>
    <div class="mt-6">
      <Button @click="openModal" :label="$t('audience.firts')" icon="pi pi-plus" iconPos="right" type="button" class="p-button-success shadow" />
    </div>
  </div>
</template>
