<template>
    <div class="w-full flex flex-col px-2 mb-5">
        <ConnectChannel :selectData="selectData" :editing="segmentIntegration.id != undefined" :title="$t('integrations.formIntegration.titleTo')" verifyType="segment" @data="saveChannel" ref="connectChannelFrom" />
        <Loader v-if="loadingSaveConfig" :title="$t('loadingData')" />
        <div v-if="channel.services_segment && projectCredential != null" id="cardServices" class="my-3">
            <ServiceSegment class="mb-3" :selectConfig="selectConfig" :channel="channel" :showAutomapper="channel.services_segment[0]?.config?.requireAutomapper" :inputsArray="channel.services_segment[0]?.inputs" :fieldsOptions="fieldsServices" @dataService="saveServiceSegment" ref="serviceSegment" />
            <Frequency :selectFrequency="selectFrequency" @frequencyData="saveFrequencySync" ref="frequencySegment" />
        </div>
        <div v-if="channel.services_segment && !segmentIntegration.id" class="w-full flex justify-end items-center px-5">
            <Button @click="createSegmentSync" :label="$t('integrations.formIntegration.buttonSave')" class="p-button-primary shadow-md ml-auto" />
        </div>
        <div v-else class="w-full flex justify-end items-center px-5">
            <Button @click="saveEditSegmentSync" :label="$t('integrations.formIntegration.buttonSaveEdit')" class="p-button-secondary shadow-md ml-auto" />
        </div>
    </div>
</template>

<script>
import ConnectChannel from "@/components/WizardNew/ConnectChannel"
import ServiceSegment from "@/components/Audience/Advanced/ServiceSegment"
import Frequency from "@/components/Audience/Advanced/Frequency"
import Button from "primevue/button"
import Loader from "@/components/UI/Loader"
import axiosClient from "@/config/AxiosClient"

export default{
    name: "FormSegmentIntegration",
    components: {
        ConnectChannel,
        ServiceSegment,
        Frequency,
        Button,
        Loader
    },
    props: {
        segment: Object,
        segmentIntegration: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data(){
        return {
            selectData: {},
            channel: {},
            projectCredential: null,
            loadingSaveConfig: false,
            selectFrequency: {},
            serviceConfig: {},
            selectConfig: {},
            fieldsServices: [],
            frequencySimpleMode: {},
            frequencyDays: {},
            frequencyHours: {},
            serviceAutomapper: [],
            priority: 0
        }
    },
    methods: {
        async saveChannel(data) {
            this.loadingSaveConfig = true
            // we set the options from the credential config
            if (data.projectCredential != null) {
                let params = {
                    project_credential_id: data.projectCredential.id
                }
                let fields = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/segment/service/${data.channel.services_segment[0].id}/fields`, params)
                this.fieldsServices = fields.data
            }
            this.channel = data.channel
            this.projectCredential = data.projectCredential
            this.loadingSaveConfig = false
        },
        saveServiceSegment(data) {
            this.serviceConfig = data.config
            this.serviceAutomapper = data.automapper
            this.priority = data.priority
        },
        async createSegmentSync() {
            this.$refs.serviceSegment.verifyServiceData()
            this.$refs.frequencySegment.saveFrequencySettings()

            await this.segmentConfigSync()
        },
        async saveEditSegmentSync() {
            this.$refs.serviceSegment.verifyServiceData()
            this.$refs.frequencySegment.saveFrequencySettings()
            await axiosClient.put(`${process.env.VUE_APP_URL_API}/api/2.0/segment/integration/${this.segmentIntegration.id}`, {
                segment_id: this.segment.id,
                service_segment_id: this.channel.services_segment[0].id,
                project_credential_id: this.projectCredential.id,
                config: this.serviceConfig,
                automapper: this.serviceAutomapper,
                config_schedule: this.frequencySimpleMode
                ? null
                : {
                    dailyAt: this.frequencyHours || "07:00",
                    days: this.frequencyDays || [0, 1, 2, 3, 4, 5, 6]
                    },
                priority: this.priority
            })
            this.$toast.success(this.$t("utils.toast.syncEdited"))
            this.$emit('finish');
        },
        async segmentConfigSync() {
            if (this.frequencySimpleMode == true) {
                let config = {
                    segment_id: this.segment.id,
                    service_segment_id: this.channel.services_segment[0].id,
                    project_credential_id: this.projectCredential.id,
                    config: this.serviceConfig,
                    automapper: this.serviceAutomapper,
                    priority: this.priority
                }
                await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/segment/integration`, config)
            } else {
                if(this.frequencyDays.length == 0){
                    this.$toast.error(this.$t("audience.formSegmentIntegration.daysValidate"))
                    return;
                }
                if(this.frequencyHours.length == 0){
                    this.$toast.error(this.$t("audience.formSegmentIntegration.hoursValidate"))
                    return;
                }

                let config = {
                    segment_id: this.segment.id,
                    service_segment_id: this.channel.services_segment[0].id,
                    project_credential_id: this.projectCredential.id,
                    config: this.serviceConfig,
                    automapper: this.serviceAutomapper,
                    config_schedule: {
                        dailyAt: this.frequencyHours,
                        days: this.frequencyDays
                    },
                    priority: this.priority
                }
                await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/segment/integration`, config)
            }
            this.$emit('finish');
        },
        async saveFrequencySync(data) {
            this.frequencySimpleMode = data.isSimpleMode
            if (this.frequencySimpleMode == false) {
                this.frequencyDays = data.week.map((d) => d.value)
                this.frequencyHours = data.hours
            }
        },
    },
    created(){
        if(this.segmentIntegration.id){
            this.selectData = { channel_id: this.segmentIntegration.project_credential.channel_id, project_credential_id: this.segmentIntegration.project_credential.id }
            this.selectConfig = { config: this.segmentIntegration.config, automapper: this.segmentIntegration.automapper, priority: this.segmentIntegration.priority }

            // Get the type of frequency ( Simple / Dynamic )
            if (this.segmentIntegration.config_schedule == null) {
                this.selectFrequency = null
            } else {
                this.selectFrequency = { days: this.segmentIntegration.config_schedule.days, dailyAt: this.segmentIntegration.config_schedule.dailyAt }
            }
        }
    }
}

</script>