<template>
  <div class="flex flex-col py-6">
    <h4 class="text-xl font-semibold leading-tight text-gray-800">{{ $t("Ready-to-use segment library") }}</h4>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 py-6">
      <Card v-for="(segment, id) in segments" :key="id" class="border shadow-md">
        <template #content>
          <div class="flex flex-col space-y-3">
            <h4 class="text-sm font-semibold leading-tight text-green-500 line-clamp-none md:line-clamp-2 md:h-10">{{ $t(segment.name) }}</h4>
            <div class="flex flex-col space-y-3">
              <div>
                <div class="flex-center mb-3 space-x-2">
                  <Icon icon="fxemoji:clipboard" class="w-4 h-4 inline-block" />
                  <span class="text-xs text-gray-500 font-semibold">{{ $t("Description") }}:</span>
                </div>
                <p class="text-xs font-normal text-gray-500 leading-relaxed line-clamp-none md:line-clamp-2" :title="$t(`${segment.name} description`)">{{ $t(`${segment.name} description`) }}</p>
              </div>
              <div>
                <div class="flex-center mb-3 space-x-2">
                  <Icon icon="fxemoji:lightbulb" class="w-4 h-4 inline-block" />
                  <span class="text-xs text-gray-500 font-semibold">{{ $t("Suggested marketing plan") }}:</span>
                </div>
                <p class="text-xs font-normal text-gray-500 leading-relaxed line-clamp-none md:line-clamp-2" :title="$t(`${segment.name} marketing`)">{{ $t(`${segment.name} marketing`) }}</p>
              </div>
            </div>
            <div class="flex-center space-x-3">
              <Button v-if="!segment.form.successful" @click="addSegment(segment)" :loading="segment.form.busy" type="button" :label="$t('Add to my segments')" icon="pi pi-plus" iconPos="right" class="p-button-sm p-button-light shadow" />
              <Button v-else type="button" :label="$t('Added')" icon="pi pi-check" iconPos="right" class="p-button-sm p-button-light shadow" :disabled="true" />
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue"
import Card from "primevue/card"
import Button from "primevue/button"
import FormClient from "@/config/FormClient"

export default {
  props: ["library"],
  emits: ["storeLib", "refreshList"],

  components: {
    Icon,
    Card,
    Button
  },
  data() {
    return {
      currentProject: this.$route.params.id
    }
  },
  computed: {
    segments() {
      return this.library.map((segment) => {
        segment.form = new FormClient({
          name: this.$t(segment.name)
        })
        return segment
      })
    }
  },
  methods: {
    async addSegment(segment) {
      await segment.form.post(`/api/1.0/projects/${this.currentProject}/segment/library/${segment.id}`)
      this.$emit("refreshList")
    }
  }
}
</script>
