<template>
  <div class="w-full flex flex-col items-start">
    <div class="border-b-2 border-blue-100 bg-gray-50 w-full">
      <Title title="Profile" />
    </div>
    <div class="lg:flex lg:flex-col lg:justify-start lg:items-center space-y-6 lg:space-y-0 h-full w-full">
      <div class="grid grid-cols-12 gap-4 w-full">
        <!--GENDER-->
        <div class="col-span-12 lg:col-span-6 my-5 lg:my-0">
          <div class="relative" v-click-away="closeGenderSelect">
            <label for="select_channel" class="label">Gender</label>
            <button id="select_channel" class="btn-xs btn-select" @click="$store.state.Menu.isSelectGenderOpen = !$store.state.Menu.isSelectGenderOpen">
              <span>{{ genderSelected }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down w-5 h-5 ml-4 text-blue-500" width="28" height="28" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="6 9 12 15 18 9" />
              </svg>
            </button>
            <!-- Dropdown menu -->
            <TransitionFade>
              <ul class="w-full absolute overflow-y-auto left-0 mt-2 bg-white dark:bg-gray-800 rounded-lg shadow-xl z-20 border divide-y divide-gray-100" v-if="$store.state.Menu.isSelectGenderOpen">
                <li class="h-14 w-full text-sm flex flex-col items-start justify-center cursor-pointer hover:bg-gray-50 transition ease-out duration-300" v-for="(gender, i) in genderOptions" :key="i" @click="selectGenderOption(gender)">
                  <span class="font-semibold text-gray-500 inline-block px-2">{{ gender }}</span>
                </li>
              </ul>
            </TransitionFade>
          </div>
        </div>

        <!--GEOLOCALIZATION-->
        <div class="col-span-12 lg:col-span-6 my-5 lg:my-0">
          <div class="relative" v-click-away="closeGeolocalizationSelect">
            <label for="select_geolocalization" class="label">Geolocalization</label>
            <button id="select_geolocalization" class="btn-xs btn-select" @click="$store.state.Menu.iseSelectGeolocalizationOpen = !$store.state.Menu.iseSelectGeolocalizationOpen">
              <span>{{ geolocalizationSelected }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down w-5 h-5 ml-4 text-blue-500" width="28" height="28" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <polyline points="6 9 12 15 18 9" />
              </svg>
            </button>
            <!-- Dropdown menu -->
            <TransitionFade>
              <ul class="w-full absolute overflow-y-auto left-0 mt-2 bg-white dark:bg-gray-800 rounded-lg shadow-xl z-20 border divide-y divide-gray-100" v-if="$store.state.Menu.iseSelectGeolocalizationOpen">
                <li class="h-14 w-full text-sm flex flex-col items-start justify-center cursor-pointer hover:bg-gray-50 transition ease-out duration-300" v-for="(city, i) in city" :key="i" @click="selectGeolocalizationOption(city)">
                  <span class="font-semibold text-gray-500 inline-block px-2">{{ city }}</span>
                </li>
              </ul>
            </TransitionFade>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TransitionFade from "@/components/TransitionFade"
import Title from "@/components/Audience/Basic/Title"

export default {
  props: [],
  emits: [],
  components: {
    TransitionFade,
    Title
  },
  data() {
    return {
      geolocalizationSelected: "Select",
      city: ["Buenos Aires", "Santa Fe", "Mendoza", "Cordoba"],
      genderSelected: "Select",
      genderOptions: ["Male", "Female", "Null"]
    }
  },
  methods: {
    closeGenderSelect: function () {
      this.$store.dispatch("Menu/closeSelectGender")
    },
    selectGenderOption: function (val) {
      this.genderSelected = val
      this.$store.dispatch("BasicSegment/selectGenderProfile", this.genderSelected)
      this.closeGenderSelect()
    },
    closeGeolocalizationSelect: function () {
      this.$store.dispatch("Menu/closeSelectGeolocalization")
    },
    selectGeolocalizationOption: function (val) {
      this.geolocalizationSelected = val
      this.$store.dispatch("BasicSegment/selectGeolocalizationProfile", this.geolocalizationSelected)
      this.closeGeolocalizationSelect()
    }
  }
}
</script>

<style lang="scss" scoped></style>
