<template>
  <div class="w-full flex flex-col items-star">
    <div class="border-b-2 border-blue-100 bg-gray-50 w-full">
      <Title title="Rfm" />
    </div>
    <div class="lg:flex lg:flex-col lg:justify-start lg:items-center lg:space-y-0 h-full w-full">
      <div class="grid grid-cols-12 gap-4 w-full">
        <div class="col-span-12 lg:col-span-8 mt-5 md:mt-0">
          <div class="w-full flex flex-row items-center justify-between">
            <div class="w-30">
              <label class="label mx-auto w-full">Recency</label>
              <div class="w-full block my-5" v-for="index in 3" :key="index">
                <button class="h-16 w-16 mx-auto rounded-full flex items-center justify-center text-gray-400 hover:text-blue-500 hover:bg-gray-200 focus:outline-none">
                  <input type="checkbox" v-model="recency[index]" @click="selectOption" />
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calendar-event" width="44" height="44" viewBox="0 0 24 24" stroke-width="1" stroke="currentcolor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="4" y="5" width="16" height="16" rx="2" />
                    <line x1="16" y1="3" x2="16" y2="7" />
                    <line x1="8" y1="3" x2="8" y2="7" />
                    <line x1="4" y1="11" x2="20" y2="11" />
                    <rect x="8" y="15" width="2" height="2" />
                  </svg>
                </button>
                <p class="text-center text-gray-400 text-xs hover:text-gray-800 mt-1">Champions</p>
              </div>
            </div>
            <div class="w-30">
              <label class="label mx-auto">Frequency</label>
              <div class="w-full block my-5" v-for="index in 3" :key="index">
                <button class="h-16 w-16 mx-auto rounded-full flex items-center justify-center text-gray-400 hover:text-blue-500 hover:bg-gray-200 focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-refresh" width="44" height="44" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
                    <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
                  </svg>
                </button>
                <p class="text-center block text-gray-400 text-xs hover:text-gray-800 mt-1">Champions</p>
              </div>
            </div>
            <div class="w-30">
              <label class="label mx-auto w-full">Money</label>
              <div class="w-16 block my-5" v-for="index in 3" :key="index">
                <button class="mx-auto h-16 w-full rounded-full flex items-center justify-center text-gray-400 hover:text-blue-500 hover:bg-gray-200 focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-coin" width="44" height="44" viewBox="0 0 24 24" stroke-width="1" stroke="currentcolor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="12" cy="12" r="9" />
                    <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1" />
                    <path d="M12 6v2m0 8v2" />
                  </svg>
                </button>
                <p class="text-center mx-auto block text-gray-400 text-xs hover:text-gray-800 mt-1">Champions</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Audience/Basic/Title"

export default {
  components: {
    Title
  },
  data: function () {
    return {
      recency: [],
      frequency: [],
      money: [],
      rfmSelected: []
    }
  },
  methods: {
    selectOption: function () {
      //
    }
  }
}
</script>

<style lang="scss" scoped></style>
