<template>
  <vue-final-modal v-model="isModalOpen" @keydown.esc="ModalClose" tabindex="0">
    <div class="h-screen w-full bg-white flex flex-col">
      <div id="modal_header" class="h-16 border-b border-gray-200 px-4">
        <div class="h-full w-full px-2 mx-auto container flex flex-row items-center justify-between">
          <Button
            v-show="(segment.mode == 'new' && $store.state.Audience.typeSelected == 'custom') || $store.state.Audience.typeSelected == 'library' || $store.state.Audience.typeSelected == 'basic' || $store.state.Audience.typeSelected == 'code'"
            @click="TypeSelected('menu')" type="button" icon="pi pi-arrow-left"
            class="p-button-sm p-button-light shadow" />
          <h4 class="text-gray-500 font-semibold uppercase" v-if="$store.state.Langs.selected.lang == 'en'">
            {{ segment.mode == "update" ? "Updating Segment" : "New Segment" }}</h4>
          <h4 class="text-gray-500 font-semibold uppercase" v-if="$store.state.Langs.selected.lang == 'es'">
            {{ segment.mode == "update" ? "Actualizando Segmento" : "Nuevo Segmento" }}</h4>
          <CancelButton @ModalClose="ModalClose" />
        </div>
      </div>
      <Loader v-if="loadingGeneral" :title="$t('loadingData')" class="w-full h-full" />
      <div v-if="!loadingGeneral" id="modal_body" class="h-full overflow-y-auto pb-10 px-4">
        <!-- OPTIONS SEGMENTS-->
        <div
          class="h-full w-screen sm:w-3/4 2xl:w-4/6 bg-white container mx-auto flex flex-row items-start justify-center pl-4">
          <!--MENU-->
          <div id="menu" class="w-full mt-32" v-show="$store.state.Audience.typeSelected === 'menu'">
            <div class="h-full w-full flex flex-col xl:flex-col items-center justify-center gap-4">
              <ButtonMenu @click="getLibrary" @TypeSelected="TypeSelected" type="library"
                          :title="$t('audience.library')" :subtitle="$t('audience.librarySub')" />
              <!-- <ButtonMenu @TypeSelected="TypeSelected" type="basic" title="Basic Segment" subtitle="Cutstomize your own audience segment to reach exactly who you want to reach" /> -->
              <ButtonMenu @TypeSelected="TypeSelected" type="custom" :title="$t('audience.advanced')"
                          :subtitle="$t('audience.advancedSub')" />
            </div>
          </div>
          <!--LIB SEGMENTS-->
          <div id="library" class="w-full" v-show="$store.state.Audience.typeSelected == 'library'">
            <div v-if="libLoader"
                 class="w-full h-screen absolute top-0 right-0 bottom-0 bg-black bg-opacity-40 z-50 flex justify-center items-center">
              <Spinner size="large" color="white" />
            </div>
            <LibraryList :library="$store.state.Audience.library" @refreshList="$emit('refreshList')"
                         @storeLib="storeLib" />
          </div>
          <!--BASIC SEGMENTS-->
          <div id="basic" class="w-full pb-10" v-show="$store.state.Audience.typeSelected == 'basic'">
            <div class="w-full space-y-8 my-8">
              <div class="inline-flex w-full justify-between">
                <h4 class="text-gray-800 font-semibold uppercase text-lg">basic segment</h4>
                <button @click="alert('guardando')" class="btn btn-xs btn-primary" v-show="segment.mode == 'new'">save
                </button>
              </div>
              <div class="w-full grid grid-cols-12 gap-x-16">
                <div class="col-span-12 md:col-span-6 space-y-16 px-0 lg:px-10">
                  <SelectGroup />
                  <Profile />
                  <Rfm />
                </div>
                <div class="col-span-12 md:col-span-6 space-y-16 px-0 lg:px-10">
                  <Collection />
                  <Transactions />
                </div>
              </div>
            </div>
          </div>
          <!--CUSTOM SEGMENTS-->
          <div id="custom" class="w-full" v-show="$store.state.Audience.typeSelected === 'custom'">
            <div class="space-y-8 mt-8 mb-32">
              <h4 class="text-gray-800 font-semibold uppercase text-lg">Advanced segment</h4>

              <TransitionFade>
                <div class="alert alert-error px-1 py-2.5 mt-1 text-sm" v-if="error">
                  <div class="flex-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         class="w-5 h-5 mx-2 stroke-current">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                    </svg>
                    <label>{{ errorMessage }}</label>
                  </div>
                </div>
              </TransitionFade>

              <!-- Segment name-->
              <div>
                <div class="form-group">
                  <label for="name"
                         class="form-label">{{ $t("audience.segmentName") }}
                  </label>
                  <input type="text" class="dl-input my-0" v-model.trim="segment.name" />
                </div>

                <div class="form-group">
                  <label for="name"
                         class="form-label">{{ $t("audience.tags") }}
                  </label>
                  <AutoComplete v-model="segment.tags" id="tags" name="tags" class="p-inputtext-sm flex flex-1 flex-col w-full" multiple :suggestions="tags" @complete="searchTags" />
                </div>

                <div class="form-group">
                  <label for="name" class="form-label">{{ $t("audience.notes") }}
                  </label>
                  <Textarea v-model="segment.notes" rows="2" id="notes" name="notes" class="p-inputtext-sm" />
                </div>

                <div class="w-full mt-3 flex flex-row justify-end items-center">
                  <Button :disabled="validateSegmentName" @click="storeSegment"
                          v-show="segment.mode == 'new' && isSyncDisable" :label="$t('audience.save')"
                          class="p-button-primary shadow-sm" />
                  <Button @click="updateSegment" v-show="segment.mode == 'update'" :label="$t('audience.update')"
                          class="p-button-success shadow-sm" />
                </div>
              </div>

              <!-- segment integrations -->
              <div class="w-full flex flex-col items-start justify-start space-y-3">
                <Message class="w-full py-2" v-if="showSyncNotification" severity="info" :closable="true">
                  {{ $t("audience.syncNotification") }}
                </Message>

                <TabView class="w-full tab-view-bg-white">
                  <!-- builders-->
                  <TabPanel>
                    <template #header>
                      <div class="flex-center-center space-x-2">
                        <Icon icon="tabler:columns" class="w-5 h-5" />
                        <span>{{ $t("audience.segmentTitle") }}</span>
                      </div>
                    </template>
                    <div id="NewSegment_step_1" class="w-full space-y-4 mb-3">
                      <div class="flex justify-end">
                        <Simulator :data="$store.state.Audience.data" :loading="$store.state.Audience.simulationLoading"
                                   @simulateSegment="simulateSegment" />
                      </div>
                      <div class="w-full card-dashed">
                        <div class="w-full inline-flex justify-between items-center mb-3">
                          <h4 class="card-dashed-title">{{ $t("audience.profiles") }}</h4>
                        </div>
                        <div class="card-dashed-body">
                          <div id="builder_profile"></div>
                        </div>
                      </div>
                      <div class="card-dashed">
                        <h4 class="card-dashed-title">{{ $t("audience.sales") }}</h4>
                        <div class="card-dashed-body">
                          <div id="builder_sales"></div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>

                  <!-- sync -->
                  <TabPanel v-if="$verifyPermission(permissions, ['v2.client.segment.integration.create'])">
                    <template #header>
                      <div class="flex-center-center space-x-2">
                        <Icon icon="tabler:refresh" class="w-5 h-5" />
                        <span>{{ $t("audience.syncro") }}</span>
                      </div>
                    </template>
                    <div class="w-full my-2">
                      <div v-if="!segment.isCreated">
                        <Message class="w-full py-2" severity="info" :closable="false">{{ $t("audience.createSegment")
                          }}
                        </Message>
                        <div class="flex flex-col items-center mt-20">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                               class="w-32 text-gray-300">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                          </svg>
                          <span
                            class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.notfound")
                            }}</span>
                        </div>
                      </div>

                      <div v-else>
                        <p>{{ $t("audience.selectApp") }}</p>
                        <Button @click="syncModal" :label="$t('audience.newSync')" class="p-button-secondary" />

                        <!-- created sync list -->
                        <div v-if="syncList.length">
                          <div class="mt-8 flex flex-col">
                            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                  <table class="min-w-full divide-y divide-gray-300">
                                    <thead class="bg-gray-50">
                                    <tr>
                                      <th scope="col"
                                          class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
                                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        {{ $t("audience.state") }}
                                      </th>
                                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        {{ $t("audience.frequency") }}
                                      </th>
                                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                          v-tooltip.top="'MailUp'">{{ $t("audience.group") }}
                                      </th>
                                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        {{ $t("audience.createDate") }}
                                      </th>
                                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        {{ $t("audience.updateDate") }}
                                      </th>
                                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        {{ $t("audience.actions") }}
                                      </th>
                                    </tr>
                                    </thead>
                                    <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr v-for="(sync, index) in syncList" :key="index">
                                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                        <div class="flex items-center">
                                          <div class="h-10 w-10 flex-shrink-0">
                                            <img :src="sync.project_credential.channel.avatar_short" alt="img"
                                                 class="w-18 h-12" />
                                          </div>
                                          <div class="ml-4">
                                            <div class="font-medium text-gray-900">
                                              <p class="font-bold text-md text-gray-600 uppercase">
                                                {{ sync.project_credential.channel.name }}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <span
                                          class="inline-flex rounded-full bg-green-100 text-green-800 px-2 text-xs font-semibold leading-5"
                                          :class="(sync.is_active == true ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800')">{{ (sync.is_active == 1 ? $t("audience.active") : $t("audience.inactive"))
                                          }}</span>
                                      </td>
                                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {{ sync.config_schedule == null ? $t("audience.simple") : $t("audience.dynamic")
                                        }}
                                      </td>
                                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {{ sync.config.group_id || "-" }}
                                      </td>
                                      <!-- FIXME: cambiar a created y updated de SYNC -->
                                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {{ $moment(sync.created_at).format("DD-MM-YYYY, hh:mm") || $t("audience.notData")
                                        }}
                                      </td>
                                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        {{ sync.sync_at ? $moment(sync.sync_at).format("DD-MM-YYYY, hh:mm") : $t("audience.notData")
                                        }}
                                      </td>
                                      <td class="relative whitespace-nowrap p-4 text-left text-sm font-medium">
                                        <!-- <Button label="Logs" class="p-button-outlined p-button-sm" /> -->
                                        <Button @click="editSegmentSync(sync)" :label="$t('audience.edit')"
                                                icon="pi pi-pencil" iconPos="left"
                                                class="mx-2 p-button-sm p-button-secondary" />
                                        <Button @click="deleteSegmentSync(sync.id)" :label="$t('audience.delete')"
                                                icon="pi pi-trash" iconPos="left" class="p-button-danger p-button-sm" />
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-else class="flex justify-evenly items-center">
                          <div class="flex flex-col items-center mt-20">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" class="w-32 text-gray-300">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                            <span
                              class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("accounts.notfound")
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </div>

            <Dialog :header="$t('audience.syncro')" v-model:visible="formConnection.show"
                    :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '70vw' }" :modal="true">
              <FormSegmentIntegration :segment="{id: this.segment.segmentId}" :segmentIntegration="segmentIntegration"
                                      @finish="finishSegmentIntegration()"></FormSegmentIntegration>
            </Dialog>
          </div>
        </div>
        <!-- COLLECTION MODAL-->
        <transition enter-from-class="transition duration-300" enter-active-class="opacity-0"
                    enter-to-class="opacity-100" leave-from-class="transition duration-300"
                    leave-active-class="opacity-100" leave-to-class="opacity-0">
          <div class="fixed top-0 inset-0 w-full h-auto z-20 bg-white duration-300 overflow-y-auto"
               v-if="$store.state.Menu.isModalCollectionOpen">
            <Content :segment="segment" :showTopNavbar="$store.state.Menu.isTopNavModalCollection" />
          </div>
        </transition>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import $ from "jquery"
import QueryBuilder from "jQuery-QueryBuilder"
import QueryBuilderConfig from "@/config/QueryBuilderConfig"
import TransitionFade from "@/components/TransitionFade"
import ButtonMenu from "@/components/Audience/ButtonMenu"
import CancelButton from "@/components/UI/CancelButton"
import LibraryList from "@/components/Audience/Libraries/LibraryList"
import Simulator from "@/components/Audience/Advanced/Simulator"
import Profile from "@/components/Audience/Basic/Profile"
import Transactions from "@/components/Audience/Basic/Transactions"
import SelectGroup from "@/components/Audience/Basic/SelectGroup"
import Collection from "@/components/Audience/Basic/Collection"
import Content from "@/views/Content"
import Rfm from "@/components/Audience/Basic/Rfm"
import * as yup from "yup"
import axiosClient from "@/config/AxiosClient"
import Spinner from "@/components/UI/Spinner.vue"
/* import ConnectChannel from "@/components/WizardNew/ConnectChannel"
import ServiceSegment from "@/components/Audience/Advanced/ServiceSegment"
import Frequency from "@/components/Audience/Advanced/Frequency" */
import FormSegmentIntegration from "./SegmentIntegration/FormSegmentIntegration.vue"
import Button from "primevue/button"
import { Icon } from "@iconify/vue"
import TabView from "primevue/tabview"
import TabPanel from "primevue/tabpanel"
import Message from "primevue/message"
import Dialog from "primevue/dialog"
import { mapGetters } from "vuex"
import { sprintf } from "sprintf-js"
import ApiRoute from "@/config/ApiRoute"
import AutoComplete from "primevue/autocomplete"
import Textarea from "primevue/textarea"
import Loader from '@/components/UI/Loader';


export default {
  name: "NewSegmentModal",
  props: {
    error: Boolean,
    errorMessage: String,
    updatingSegment: Boolean,
    openModal: Boolean
  },
  components: {
    TransitionFade,
    ButtonMenu,
    CancelButton,
    LibraryList,
    Simulator,
    Profile,
    Transactions,
    SelectGroup,
    Collection,
    Content,
    Rfm,
    Spinner,
    Button,
    Icon,
    TabView,
    TabPanel,
    Message,
    Dialog,
    FormSegmentIntegration,
    AutoComplete,
    Textarea,
    Loader
  },
  data: function() {
    QueryBuilder
    const nameValidation = yup.object({
      name: yup.string().required()
    })
    return {
      tags: [],
      filterKeyword: "",
      sort: "id,DESC",
      limit: 50,
      default: {
        sort: "id,DESC",
        limit: 50
      },
      nameValidation,
      isModalOpen: false,
      typeSelected: "menu",
      tabStep1: true,
      show: true,
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      library: [],
      libLoader: false,
      loading: true,
      loadingGeneral: true,
      query: {
        audience: "",
        sales: ""
      },
      taskLog: {
        data: []
      },
      builderProfileRules: null,
      builderProfileMongo: {},
      builderSalesRules: null,
      builderSalesMongo: {},
      simulation: {
        count: 0,
        data: []
      },
      segment: {
        step: 1,
        mode: "new",
        name: "",
        segmentId: null,
        isCreated: false,
        tags: [],
        notes: ""
      },
      inputs: [
        {
          name: "account_name",
          type: "text",
          validation: ["required"],
          value: ""
        },
        {
          name: "key",
          type: "text",
          validation: ["required"],
          value: ""
        },
        {
          name: "token",
          type: "text",
          validation: ["required"],
          value: ""
        },
        {
          name: "url",
          type: "text",
          validation: ["required"],
          value: ""
        }
      ],
      // New sync
      fieldsServices: [],
      isSyncDisable: true,
      showSyncNotification: false,
      formConnection: {
        show: false,
        channel: {},
        project: {},
        title: "Nueva conexion"
      },
      selectDataTo: {},
      projectCredentialTo: {},
      channelTo: {},
      loadingSaveConfig: true,
      segmentSyncInputs: [],
      serviceConfig: {},
      selectConfig: {},
      serviceAutomapper: {},
      selectFrequency: {},
      frequencyDays: {},
      frequencyHours: {},
      frequencySimpleMode: {},
      syncList: [],
      syncSelectedId: null,
      isNewSync: true,
      segmentIntegration: {}
    }
  },
  async created() {
    // await this.getCredentials()
    this.isModalOpen = true
    this.loadingGeneral = true
    if (!this.getAudienceKeys.length) {
      await this.$store.dispatch("Audience/getAudienceKeys", this.currentProject)
      await this.$store.dispatch("Audience/getAudienceKeysFilter", this.currentProject)
    }
    if(this.$store.state.Audience.updatingSegment){
      await this.popupFormSegment(this.$store.state.Audience.updatingSegment)
    }else{
      await this.popupFormSegment()
    }
    this.loadingGeneral = false
  },
  watch: {
    toggleGroup: {
      handler(newVal) {
        if (!newVal) {
          this.isGroupSelected = ""
        } else this.isGroupSelected = "Automatic"
      }
    },
    "$store.state.Menu.isNewSegmentModalOpen": {
      handler(newVal) {
        if (newVal) {
          this.$store.state.Audience.updatingSegment ? this.popupFormSegment(this.$store.state.Audience.updatingSegment) : this.popupFormSegment()
        }
      }
    }
  },
  computed: {
    ...mapGetters({ permissions: "Projects/permissionsForProject" }),
    getCollection() {
      return this.$store.state.Audience.collection
    },
    getAudienceKeys() {
      return this.$store.state.Audience.projectAudienceKeys
    },
    getAudienceKeysFilter() {
      return this.$store.state.Audience.projectAudienceKeysFilter
    },
    stepperProgress() {
      return (100 / 2) * (this.scheduleStep - 1) + "%"
    },
    validateSegmentName() {
      return this.emptyName()
    },
    getOpenModal() {
      return this.openModal
    },
    isEditing() {
      return this.syncSelectedId ? true : false
    },
    config() {
      return {
        segment_id: this.segment.segmentId,
        service_segment_id: this.channelTo ? this.channelTo.services_segment[0].id : null,
        project_credential_id: this.projectCredentialTo.id,
        config: this.serviceConfig,
        automapper: this.serviceAutomapper,
        config_schedule: this.frequencySimpleMode
          ? null
          : {
            dailyAt: this.frequencyHours || "07:00",
            days: this.frequencyDays || [0, 1, 2, 3, 4, 5, 6]
          }
      }
    }
  },
  methods: {
    async searchTags(event) {
      let response = await axiosClient.get(sprintf(ApiRoute.tags.index) + "?keyword=" + event.query + "&project=" + this.currentProject)
      this.tags = response.data.data.map((tag) => tag.name)
      this.tags.push(event.query)
    },
    builderBuild() {
      this.builderProfileRules = $("#builder_profile").queryBuilder("getRules")
      this.builderProfileMongo = $("#builder_profile").queryBuilder("getMongo")
      this.builderSalesRules = $("#builder_sales").queryBuilder("getRules")
      this.builderSalesMongo = $("#builder_sales").queryBuilder("getMongo")
    },
    simulateBuilder() {
      this.builderProfileRules = $("#builder_profile").queryBuilder("getRules")
      this.builderProfileMongo = $("#builder_profile").queryBuilder("getMongo")
      this.builderSalesRules = $("#builder_sales").queryBuilder("getRules")
      this.builderSalesMongo = $("#builder_sales").queryBuilder("getMongo")
    },
    configSegment() {
      this.builderBuild()
      let result = {
        name: this.segment.name,
        query: {
          version: "2",
          audience: JSON.stringify(this.builderProfileMongo),
          sales: JSON.stringify(this.builderSalesMongo)
        },
        rules: {
          version: "2",
          audience: (this.builderProfileRules = JSON.stringify(this.builderProfileRules)),
          sales: (this.builderSalesRules = JSON.stringify(this.builderSalesRules)),
          type: this.$store.state.Audience.typeSelected
        },
        tags: this.segment.tags,
        notes: this.segment.notes
        // custom: this.segment.custom
      }
      return result
    },
    storeLib: function(idLibrary) {
      this.$emit("storeLibrary", idLibrary)
    },
    async storeSegment() {
      let currentProject = this.currentProject
      let segment = this.configSegment()
      this.loading = true
      let storeSegment = await this.$store.dispatch("Audience/storeSegment", { currentProject, segment })

      if (storeSegment.status == 200 || storeSegment.status == 201 || storeSegment.status == 204) {
        this.handleRes(storeSegment, "stored", segment.name)
        this.segment.segmentId = storeSegment.data.segment_id
        this.isSyncDisable = false
        this.segment.isCreated = true
        this.showSyncNotification = true
        this.$emit('refreshSegments')
      }
      this.loading = false
      this.isSyncDisable = false
    },
    async updateSegment() {
      this.loading = true
      let currentProject = this.currentProject
      let segmentId = this.segment.segmentId
      let segment = this.configSegment()

      let res = await this.$store.dispatch("Audience/updateSegment", { currentProject, segment, segmentId })
      this.handleRes(res, "updated", segment.name)
      await this.$store.dispatch("Audience/resetSimulation")
      this.$emit('refreshSegments')
      this.loading = false
      this.ModalClose()
    },
    simulateSegment() {
      this.simulateBuilder()
      let audienceMongoEvaluated = JSON.stringify(this.builderProfileMongo)
      let salesMongoEvaluated = JSON.stringify(this.builderSalesMongo)

      if ((audienceMongoEvaluated != "null" && audienceMongoEvaluated != "{\"$and\":[]}") || (salesMongoEvaluated != "null" && salesMongoEvaluated != "{\"$and\":[]}")) {
        this.loading = true
        let segment = {
          query: {
            version: "2",
            audience: audienceMongoEvaluated,
            sales: salesMongoEvaluated
          }
        }
        this.$emit("simulateSegment", segment)
        this.loading = false
      } else {
        this.$store.dispatch("Audience/resetSimulation")
        this.$toast.error(this.$t("You need to configure the audience or sales rules."))
      }
    },
    async popupFormSegment(segmentSelected = null) {
      this.resetSegment()
      this.segment.mode = "new"
      this.segment.name = ""
      this.segment.segmentId = ""
      if (segmentSelected != null) {
        this.isSyncDisable = false
        this.showSyncNotification = false
        this.segment.isCreated = true
        this.segment.mode = "update"
        this.segment.name = segmentSelected.name
        this.segment.segmentId = segmentSelected.id
        this.segment.notes = segmentSelected.notes
        this.segment.tags = segmentSelected.tags.map((tag) => tag.name)
        this.builderProfileRules = JSON.parse(segmentSelected.rules.audience)
        this.builderSalesRules = JSON.parse(segmentSelected.rules.sales)

        this.getSyncList()
      }
      this.isModalOpen = true
      this.builderLoad()
    },
    resetSegment() {
      this.segment.mode = "new"
      this.segment.name = ""
      this.segment.segmentId = null
      this.segment.isCreated = false
      this.builderProfileRules = null
      this.builderProfileMongo = null
      this.builderSalesRules = null
      this.builderSalesMongo = null
      this.simulation.count = 0
      this.simulation.data = []
    },
    builderLoad() {
      let filtersProfile = QueryBuilderConfig.AudienceObjectKeys(this.getAudienceKeysFilter)
      let filtersSales = QueryBuilderConfig.SalesObjectKeys(this.$store.state.Audience.collection)
      setTimeout(() => {
        $("#builder_profile").queryBuilder("destroy")
        $("#builder_sales").queryBuilder("destroy")
        $(document).find("#builder_profile").html("")
        $(document).find("#builder_sales").html("")
      }, 100)
      setTimeout(() => {
        $("#builder_profile").queryBuilder({
          plugins: {
            sortable: { icon: "fa fa-arrows-alt" },
            "not-group": {
              icon_checked: "far fa-check-circle",
              icon_unchecked: "far fa-circle"
            },
            "unique-filter": null
          },
          icons: {
            add_group: "far fa-object-ungroup",
            add_rule: "fa fa-plus-circle",
            remove_group: "fa fa-trash",
            remove_rule: "fa fa-trash",
            error: "fa fa-exclamation-triangle",
            sortable: "fa fa-exclamation-triangle"
          },
          filters: filtersProfile,
          allow_empty: true,
          display_errors: true,
          default_condition: "AND",
          display_empty_filter: true,
          select_placeholder: "SELECCIONE",
          default_rule_flags: {
            filter_readonly: this.$store.state.Projects.activeProject.plan == "freemium" ? true : false,
            operator_readonly: this.$store.state.Projects.activeProject.plan == "freemium" ? true : false,
            value_readonly: false,
            no_delete: this.$store.state.Projects.activeProject.plan == "freemium" ? true : false
          },
          default_group_flags: {
            condition_readonly: this.$store.state.Projects.activeProject.plan == "freemium" ? true : false,
            no_add_rule: this.$store.state.Projects.activeProject.plan == "freemium" ? true : false,
            no_add_group: this.$store.state.Projects.activeProject.plan == "freemium" ? true : false,
            no_delete: this.$store.state.Projects.activeProject.plan == "freemium" ? true : false
          }
        })
        $("#builder_profile").on("afterUpdateRuleOperator.queryBuilder", function() {
          let rule = arguments[1]
          if (rule.operator.type == "between" || rule.operator.type == "not_between") {
            rule.$el.find(".rule-value-container input").each(function(index, element) {
              $(element).attr("style", "width: 35% !important")
            })
          }
        })

        if (this.builderProfileRules != null) {
          $("#builder_profile").queryBuilder("setRules", this.builderProfileRules)
        }

        $("#builder_sales").queryBuilder({
          plugins: {
            sortable: { icon: "fa fa-arrows-alt" },
            "not-group": {
              icon_checked: "far fa-check-circle",
              icon_unchecked: "far fa-circle"
            },
            "unique-filter": null
          },
          icons: {
            add_group: "far fa-object-ungroup",
            add_rule: "fa fa-plus-circle",
            remove_group: "fa fa-trash",
            remove_rule: "fa fa-trash",
            error: "fa fa-exclamation-triangle",
            sortable: "fa fa-exclamation-triangle"
          },
          filters: filtersSales,
          allow_empty: true,
          display_errors: true,
          default_condition: "AND",
          display_empty_filter: true,
          select_placeholder: "SELECCIONE",
          default_rule_flags: {
            filter_readonly: this.$store.state.Projects.activeProject.plan == "freemium" ? true : false,
            operator_readonly: this.$store.state.Projects.activeProject.plan == "freemium" ? true : false,
            value_readonly: false,
            no_delete: this.$store.state.Projects.activeProject.plan == "freemium" ? true : false
          },

          default_group_flags: {
            condition_readonly: this.$store.state.Projects.activeProject.plan == "freemium" ? true : false,
            no_add_rule: this.$store.state.Projects.activeProject.plan == "freemium" ? true : false,
            no_add_group: this.$store.state.Projects.activeProject.plan == "freemium" ? true : false,
            no_delete: this.$store.state.Projects.activeProject.plan == "freemium" ? true : false
          }
        })
        $("#builder_sales").on("afterCreateRuleInput.queryBuilder", function() {
          let rule = arguments[1]
          if (rule.operator.type == "between" || rule.operator.type == "not_between") {
            rule.$el.find(".rule-value-container input").each(function(index, element) {
              $(element).attr("style", "width: 48% !important")
            })
          }
        })
        $("#builder_sales").on("afterUpdateRuleOperator.queryBuilder", function() {
          let rule = arguments[1]
          if (rule.operator.type == "between" || rule.operator.type == "not_between") {
            rule.$el.find(".rule-value-container input").each(function(index, element) {
              $(element).css("width", "48% !important")
            })
          }
        })

        if (this.builderSalesRules != null) {
          $("#builder_sales").queryBuilder("setRules", this.builderSalesRules)
        }
      }, 300)
    },
    addChildMailUpField() {
      this.segment.custom.mailup_groups[0].fields.push({
        from: "",
        to: "",
        default: false
      })
    },
    removeChildMailUpField(index) {
      this.segment.custom.mailup_groups[0].fields.splice(index, 1)
    },
    TypeSelected: function(newVal) {
      if (["library", "menu"].indexOf(newVal) > -1 || (["custom", "basic", "code"].indexOf(newVal) > -1 && this.$store.state.Projects.activeProject.plan != "freemium")) {
        this.$store.dispatch("Audience/changeTypeSelected", newVal)
        this.$store.state.Menu.isModalCollectionOpen ? this.$store.dispatch("Menu/closeCollectionModal") : null
      }
    },
    ModalClose: function() {
      this.$store.dispatch("Audience/resetTypeSelected")
      this.$store.dispatch("Menu/closeNewSegmentModal")
      this.resetSegment()
      this.$store.dispatch("Audience/resetSimulation")
      this.$store.dispatch("Audience/resetUpdatingSegment")
      this.$store.dispatch("Menu/closeCollectionModal")
      this.NewSegment_step_1 = true
      this.NewSegment_step_2 = false
      this.$emit("closeModal")
    },
    emptyName() {
      if (this.segment.name === "") {
        return this.segment.name === ""
      }
    },
    getLibrary() {
      if (!this.$store.state.Audience.library.length) {
        this.libLoader = true
        this.$store.dispatch("Audience/getLibrary", this.currentProject)
        setTimeout(() => {
          this.libLoader = false
        }, 800)
      }
    },
    // new sync
    syncModal() {
      this.segmentIntegration = {}
      this.formConnection.show = true
      //this.resetSyncData()
    },
    resetSyncData() {
      this.selectDataTo = {}
      this.isNewSync = true
      this.serviceConfig = null
      this.serviceAutomapper = null
      this.selectConfig = { automapper: [{ field_from: "email", field_to: "email" }] }
      this.selectFrequency = null
      this.frequencySimpleMode = null
      this.channelTo.services_segment = null
      this.projectCredentialTo = null
      this.syncSelectedId = null
    },
    async saveChannelTo(data) {
      this.segmentSyncInputs = data.inputs
      this.channelTo = data.channel
      this.projectCredentialTo = data.projectCredential
      this.loadingSaveConfig = false

      // we set the options from the credential config
      if (this.projectCredentialTo != null) {
        let config = {
          config: this.projectCredentialTo.config
        }
        let fields = await axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/segment/service/${this.channelTo.services_segment[0].id}/fields`, config)
        this.fieldsServices = fields.data
      }
    },
    saveServiceSegment(data) {
      this.serviceConfig = data.config
      this.serviceAutomapper = data.automapper
    },
    async saveFrequencySync(data) {
      this.frequencySimpleMode = data.isSimpleMode
      if (this.frequencySimpleMode == false) {
        this.frequencyDays = data.week.map((d) => d.value)
        this.frequencyHours = data.hours
      }
    },
    async segmentConfigSync() {
      if (this.frequencySimpleMode == true) {
        let config = {
          segment_id: this.segment.segmentId,
          service_segment_id: this.channelTo.services_segment[0].id,
          project_credential_id: this.projectCredentialTo.id,
          config: this.serviceConfig,
          automapper: this.serviceAutomapper,
          config_schedule: null
        }
        axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/segment/integration`, config)
      } else {
        let config = {
          segment_id: this.segment.segmentId,
          service_segment_id: this.channelTo.services_segment[0].id,
          project_credential_id: this.projectCredentialTo.id,
          config: this.serviceConfig,
          automapper: this.serviceAutomapper,
          config_schedule: {
            dailyAt: this.frequencyHours || "07:00",
            days: this.frequencyDays || [0, 1, 2, 3, 4, 5, 6]
          }
        }
        axiosClient.post(`${process.env.VUE_APP_URL_API}/api/2.0/segment/integration`, config)
      }
      this.$emit('refreshSegments')
      this.getSyncList()

    },
    async getSyncList() {
      let syncs = await axiosClient.get(`${process.env.VUE_APP_URL_API}/api/2.0/segment/${this.segment.segmentId}/integration?include=project_credential.channel`)
      this.syncList = syncs.data.data
    },
    async createSegmentSync() {
      this.formConnection.show = false
      this.$refs.serviceSegment.verifyServiceData()
      this.$refs.frequencySegment.saveFrequencySettings()

      await this.segmentConfigSync()
      await this.getSyncList()
      this.channelTo.services_segment = null
      this.projectCredentialTo = null
    },
    editSegmentSync(sync) {
      this.segmentIntegration = sync
      this.formConnection.show = true
    },
    async saveEditSegmentSync() {
      this.$refs.serviceSegment.verifyServiceData()
      this.$refs.frequencySegment.saveFrequencySettings()
      try {
        const res = await axiosClient.put(`${process.env.VUE_APP_URL_API}/api/2.0/segment/integration/${this.syncSelectedId}`, this.config)
        if (res.status == 204) {
          this.getSyncList()
          this.formConnection.show = false
          this.$toast.success(this.$t("utils.toast.syncEdited"))
          this.$emit('refreshSegments')
        } else {
          throw new Error(this.$t("utils.toast.error"))
        }
      } catch (error) {
        this.$t("utils.toast.error")
      } finally {
        this.syncSelectedId = null
        this.channelTo.services_segment = null
        this.projectCredentialTo = null
      }
    },
    onConnectChannelToMounted() {
      this.channelTo = {}
      this.projectCredentialTo = {}
    },
    async deleteSegmentSync(sync) {
      this.syncSelectedId = sync
      this.$swal({
        title: this.$t("utils.alerts.sure"),
        text: this.$t("utils.alerts.deleteText"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("utils.alerts.confirmButton"),
        cancelButtonText: this.$t("utils.alerts.cancelButton")
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await axiosClient.delete(`${process.env.VUE_APP_URL_API}/api/2.0/segment/integration/${this.syncSelectedId}`)
          if (res.status == 204) {
            this.getSyncList()
            this.$toast.success(this.$t("utils.toast.syncDeleted"))
            this.syncSelectedId = null
            this.$emit('refreshSegments')
          }
        }
      })
    },
    handleRes(res, type = "", name) {
      if (res.status == 500) this.$toast.error(this.$t("utils.toast.error"))
      if (res.status == 422) {
        this.$toast.error(`${name} ${type} Error`)
      }

      if (res.status == 204 || res.status == 200) {
        this.$toast.success(`${name} ${type} successfully`)
      }
    },
    getSegments: async function(config = {}) {
      if (!this.getCollection.length) {
        await this.$store.dispatch("Audience/getCollection", this.currentProject)
      }

      if (!this.getAudienceKeys.length) {
        await this.$store.dispatch("Audience/getAudienceKeys", this.currentProject)
        await this.$store.dispatch("Audience/getAudienceKeysFilter", this.currentProject)
      }
      let currentProject = this.currentProject
      let filter = {}
      if (this.filterKeyword) filter["filter[name][like]"] = `%${this.filterKeyword}%`
      filter.page = config.page || 1
      filter.sort = this.sort || this.default.sort
      filter.limit = config.limit || this.default.limit
      let res = await this.$store.dispatch("Audience/getSegments", { currentProject, filter })
      this.segments = res.data.data

      if (res.status == 403) {
        this.$toast.error(`${res.data.message}`)
        this.$router.push({
          name: "Projects",
          params: { id: "" }
        })
      }
    },
    async finishSegmentIntegration() {
      this.$emit('refreshSegments')
      await this.getSyncList()
      this.formConnection.show = false
    }
    // end new sync
  }
}
</script>
